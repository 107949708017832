import { appPaths } from "~/utils/paths/paths"

export const loginOrSignUpCheckedEmailStorageKey = "loginOrSignUpCheckedEmail"

export const returnUrlStorageKey = "returnUrl"

export function navigateToLoginSignupPage() {
  return navigateTo(appPaths.loginSignup)
}

export function navigateToLoginPage() {
  return navigateTo(appPaths.login)
}

export function navigateToSignupPage() {
  return navigateTo(appPaths.signup)
}

export function navigateToOriginPage() {
  if (process.client) {
    window.location.href = getReturnUrl()
  }
}

export function getReturnUrl(reset: boolean = true) {
  if (!process.client) {
    return appPaths.home
  }
  const returnUrl = localStorage.getItem(returnUrlStorageKey) || appPaths.home
  if (reset) {
    localStorage.removeItem(returnUrlStorageKey)
  }
  return returnUrl
}
