export enum customerStatus {
  prospect = "prospect",
  acquired = "acquired",
  repeat = "repeat"
}

export enum customerTypes {
  prospect = "false",
  acquired = "new",
  repeat = "recurring"
}

export const customerTypesMap = {
  [customerStatus.prospect]: customerTypes.prospect,
  [customerStatus.acquired]: customerTypes.acquired,
  [customerStatus.repeat]: customerTypes.repeat
}

export const customerCountry = "it"

export const analyticsCustomer = {
  types: customerTypes,
  country: customerCountry,
  phonePrefix: "39"
}

export const loginMethod = {
  default: "default",
  google: "google",
  facebook: "facebook",
  apple: "apple"
}

export const socialLoginStorageKey = "socialLoginMethod"

export const getCustomerType: (type: customerStatus) => customerTypes = (
  type
) => {
  return customerTypesMap[type]
}
