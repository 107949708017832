export enum TApiErrorType {
  lessThanOrEqualTo = "less_than_or_equal_to",
  outOfStock = "out_of_stock"
}

export const PRODUCT_PAGE = {
  addToCart: {
    defaultAlertProps: {
      id: "product-page-add-to-cart-event",
      type: "danger",
      notificationAlertText: "",
      isWithCloseIcon: true
    },
    apiErrorCheck: {
      statusCode: 422,
      code: "VALIDATION_ERROR"
    }
  }
}
