import type { CommerceLayerClient } from "@commercelayer/sdk"
import { isNotNullable } from "./isNotNullable"
import { productUtils } from "./product"

export const productsPrice = async <
  SearchProduct extends { productCode?: string }
>(
  cl: CommerceLayerClient,
  searchProducts: SearchProduct[]
) => {
  const uniqueSkus = [...searchProducts]
    .map((item) => item.productCode)
    .filter(isNotNullable)

  const skuListPrice = await getAllPrices(cl, uniqueSkus)

  return searchProducts.map((product) => {
    const productItem = skuListPrice?.find(
      (item) => item.code === product.productCode
    )

    const price = productItem?.prices?.[0]

    return {
      ...product,
      price: price
        ? {
            currency_code: price.currency_code,
            amount_cents: price.amount_cents,
            amount_float: price.amount_float,
            compare_at_amount_cents: price.compare_at_amount_cents,
            compare_at_amount_float: price.compare_at_amount_float,
            discountPercentage: discountPercentage(
              price.amount_cents,
              price.compare_at_amount_cents
            )
          }
        : null,
      inStock: productUtils.calculateMultiStockLocationAvailability(productItem)
    }
  })
}

/**
 * @param {number} firstNum - The first number
 * @param {number} secondNum - The second number
 * @returns {boolean} Returns true if the first number is greater than the second number
 */
export const isGreaterThan = (firstNum?: any, secondNum?: any) => {
  const newFirstNum = Number(firstNum)
  const newSecondNum = Number(secondNum)

  return !!(newFirstNum > newSecondNum)
}
