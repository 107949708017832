import { Meta, Story } from "@storybook/vue3"
import BundleProductCard from "./BundleProductCard.vue"
import { BundleProductCardProps } from "./BundleProductCard.props"
import { getImageData } from "~/utils/wrapperImage"

export default {
  title: "ProductPage/Utils/BundleProductCard",
  component: BundleProductCard,
  decorators: [
    () => ({
      template: '<div style="width: 299px"><story /></div>'
    })
  ],
  parameters: {
    backgrounds: { default: "Figma Grey" }
  }
} as Meta

const Template: Story<BundleProductCardProps> = (args) => ({
  components: { BundleProductCard },
  setup() {
    return {
      args
    }
  },
  template: '<BundleProductCard v-bind="args" />'
})

export const Unselectable = Template.bind(
  {}
) as StorybookTemplateType<BundleProductCardProps>
Unselectable.args = {
  sku: "XgZ0pFcakEUlbwQEVNg3",
  image: getImageData({
    altText: "Placeholder",
    url: "https://picsum.photos/id/669/451/515",
    width: 451,
    height: 515
  }),
  title: "Carnidyn Plus Integratore stanchezza fisica e mentale 20 bustine",
  price: 8.99,
  oldPrice: 12.99,
  path: "/"
}

export const Selectable = Template.bind(
  {}
) as StorybookTemplateType<BundleProductCardProps>

Selectable.args = {
  ...Unselectable.args,
  selectable: true,
  selected: false
}
