import { captureException } from "@sentry/browser"

export const sentryCaptureException = (
  error: any,
  component: string,
  method: string,
  action: string
) => {
  captureException(error, {
    tags: {
      component,
      method,
      action
    }
  })
}
