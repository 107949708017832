import { useState } from "nuxt/app";

export type GA4Entities = {
    gaItem: {
        item_id: string;
        item_name: string;
        index?: number | undefined;
        item_list_name?: string | undefined;
        item_brand?: string | undefined;
        item_category?: string | undefined;
        item_category2?: string | undefined;
        item_category3?: string | undefined;
        item_category4?: string | undefined;
        item_category5?: string | undefined;
        price: number;
        quantity?: number | undefined;
        discount: number;
        bundle_name?: string | undefined;
        available: "si" | "no";
        currency?: "EUR";
    };
    pageList?: {
        list_id: string;
        list_name: string;
        filters: {
            type: string;
            value: string;
        }[];
        sort: string;
    } | undefined;
    cartList: {
        item_id: string;
        item_name: string;
        index?: number | undefined;
        item_list_name?: string | undefined;
        item_brand?: string | undefined;
        item_category?: string | undefined;
        item_category2?: string | undefined;
        item_category3?: string | undefined;
        item_category4?: string | undefined;
        item_category5?: string | undefined;
        price: number;
        quantity?: number | undefined;
        discount: number;
        bundle_name?: string | undefined;
        available: "si" | "no";
        currency?: "EUR";
    }[];
    instantSearchProducts?: {
        list_id: string;
        list_name: string;
    } | undefined;
    carouselOutletProduct?: {
        list_id: string;
        list_name: string;
    } | undefined;
    carouselBrandBanner?: {
        list_id: string;
        list_name: string;
    } | undefined;
    carouselProductRails?: {
        list_id: string;
        list_name: string;
    } | undefined;
    carouselProductRailsTab?: {
        list_id: string;
        list_name: string;
    } | undefined;
    modalCartProducts?: {
        list_id: string;
        list_name: string;
    } | undefined;
    productBrandBlock?: {
        list_id: string;
        list_name: string;
    } | undefined;
    boundleProducts?: {
        list_id: string;
        list_name: string;
    } | undefined;
    railsCart?: {
        list_id: string;
        list_name: string;
    } | undefined;
};
export function useGAEntity<T extends keyof GA4Entities>(entityName: T) {
  const entity = useState<GA4Entities[T]>(entityName, undefined);
  return entity;
}
