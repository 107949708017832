
import { cmsRoutes, getRouteByTypename } from "~~/utils/cmsRoutes"
export default defineNuxtPlugin(() => {
  return {
    provide: {
      cmsRoutes,
      getRouteByTypename,
    },
  }
})
