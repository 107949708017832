import { pushDataLayer } from "~/utils/analytics/analytics"

export enum generateLeadEvent {
  generateLead = "generate_lead"
}

export enum generateLeadCategory {
  requestHelp = "richiedi_assistenza",
  findOrder = "trova_ordine",
  requestProduct = "richiedi_prodotto",
  signInNewsletter = "iscrizione_newsletter",
  signOutNewsletter = "cancellazione_newsletter"
}

export interface ClickCtaDataLayerPayload {
  event: generateLeadEvent
  category: string
  action?: string
}

export function getGenerateLeadPayload(
  category: string,
  action?: string
): ClickCtaDataLayerPayload {
  return {
    event: generateLeadEvent.generateLead,
    category,
    ...(action && { action })
  }
}

export function pushRequestHelpGenerateLeadEvent(action: string) {
  const generateLeadPayload = getGenerateLeadPayload(
    generateLeadCategory.requestHelp,
    action
  )

  pushDataLayer(generateLeadPayload)
}

export function pushFindOrderGenerateLeadEvent(action?: string) {
  const generateLeadPayload = getGenerateLeadPayload(
    generateLeadCategory.findOrder,
    action
  )

  pushDataLayer(generateLeadPayload)
}

export function pushRequestProductGenerateLeadEvent(action?: string) {
  const generateLeadPayload = getGenerateLeadPayload(
    generateLeadCategory.requestProduct,
    action
  )

  pushDataLayer(generateLeadPayload)
}

export function pushSignInNewsletterGenerateLeadEvent(action?: string) {
  const generateLeadPayload = getGenerateLeadPayload(
    generateLeadCategory.signInNewsletter,
    action
  )

  pushDataLayer(generateLeadPayload)
}

export function pushSignOutNewsletterGenerateLeadEvent(action?: string) {
  const generateLeadPayload = getGenerateLeadPayload(
    generateLeadCategory.signOutNewsletter,
    action
  )

  pushDataLayer(generateLeadPayload)
}
