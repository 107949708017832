<script setup lang="ts">
import { useUserStore } from "~/stores/user";
import { storeToRefs } from "pinia";
import { type UserAddress } from "~/composables/useAddress"
import { useAnalytics } from "~/composables/useAnalytics";
import { pushDataLayer } from "~/utils/analytics/analytics";
import {
  getInitUserBasicPayload,
  getUserAddress,
  getUserData,
  getUserDataExtra,
  getUserDataHashed,
  GtmInitUserPayload,
  initUserEvent,
  initUserTrafficType
} from "~/utils/analytics/user";
import { personalAreaPath } from "~/utils/paths/paths";

const userStore = useUserStore()
const { customerType } = useAnalytics()
const { getAllUserAddresses } = useAddress()
const { userId, user } = storeToRefs(userStore)
const isLogged = useIsLogged()
const route = useRoute()

const allUserAddresses = ref<UserAddress[]>([])
const origin = ref('')
const windowLocation = ref<any>()
const trafficType = useRuntimeConfig().public.IS_PRODUCTION == 'no' ? 'internal' : false

if (process.client) {
  windowLocation.value = window.location
  origin.value = windowLocation?.value.origin
}

const setUserAddressesAndSendGtm = async () => {
  // As per TF-2841, we only gotta fetch the address when the user is set, the thing is that for some reason the id is set when logging in from guest BUT with the wrong token, so we check if the (new) user is set before fetching its addresses
  if (user.value) {
    allUserAddresses.value = await getAllUserAddresses()
  }

  sendDataToGTM();
}

const GTMInitPayload = computed(() => {
  let payload: GtmInitUserPayload = getInitUserBasicPayload(!!isLogged.value, customerType.value)

  if (trafficType) {
    payload = {
      ...payload,
      traffic_type: initUserTrafficType.internal
    }
  }

  const userValue = user.value

  if (isLogged?.value) {
    const address = userValue ? getUserAddress(allUserAddresses.value, userValue) : null
    const birthday = userValue?.birthday ? userValue?.birthday.split('/').reverse().join('-') : null

    payload = {
      ...payload,
      user_id: userId.value,
      user_data: userValue ? getUserData(userValue, address) : null,
      user_data_hashed: userValue ? getUserDataHashed(userValue, address) : null,
      user_data_extra: userValue ? getUserDataExtra(birthday, address) : null
    }
  }

  return payload
})

const sendDataToGTM = () => {
  if (process.client) {
    const payload = GTMInitPayload.value;
    pushDataLayer({
      event: initUserEvent.initUser,
      ...payload
    });
  }
}

if (route.fullPath.indexOf(personalAreaPath) !== -1) {
  await setUserAddressesAndSendGtm()
}

watch(() => route.fullPath, () => {
  sendDataToGTM();
});

watch(
  [user, userId, isLogged],
  async ([newUser, newUserId, newLogged], [oldUser, oldUserId]) => {
    if ((newUserId && !oldUser && !!newUser && !!newLogged) || (newLogged === null && !oldUserId)) {
      await setUserAddressesAndSendGtm()
    }
  })
</script>

<template></template>
