/* eslint-disable camelcase */
import type { LineItemCreate, Sku, LineItem } from "@commercelayer/sdk"
import { WrapperImageProps } from "~/components/Utils/WrapperImage.props"
import { md } from "~/utils/breakpoints"
import { storeToRefs } from "pinia"
import { useUserStore } from "~/stores/user"
import { DiscountCodeProps } from "~/components/Card/DiscountCode.props"
import { getDiscountCodes } from "~/utils/user/getDiscountCodes"
import type { Order as OrderCL } from "@commercelayer/sdk"

// TODO ask the reason fo the variable T
export type LineItemMetadata /* <T extends keyof GA4Events> */ = {
  product_image: WrapperImageProps
  slug: string
  unitType: string
  expirationDate: string
  isDeductable: boolean
  price: number
  oldPrice: number
  ga4Item: any // GA4Events[T]
  inStock: boolean
}

export type LineItem_<Item extends LineItem["item"]> = LineItem & {
  item: Item
}

export default () => {
  const { $cl } = useNuxtApp()
  const config = useRuntimeConfig()
  const route = useRoute()

  const userStore = useUserStore()
  const { userId, user } = storeToRefs(userStore)
  const isLogged = useIsLogged()

  const BASE_CART_COOKIE_NAME = "cl_cart_order_id"
  const LOGGED_CART_COOKIE_NAME = "cl_logged_cart_order_id"

  const preCheckoutModal = useModal("pre-checkout")
  const addMoreModal = useModal("add-more")
  const addToCartModalMobile = useModal("add-to-cart-mini-cart")

  const cartInitialized = useState(`cart-initialized`, () => false)

  const addToCartModalDesktop = useState(
    `add-to-cart-modal-desktop`,
    () => false
  )
  const discountCodes = useState<DiscountCodeProps[]>(
    "discount-codes",
    () => []
  )

  const lastAdded = useState<LineItem_<Sku>>(`last-added`)

  const timerId = useState<NodeJS.Timeout>(`timer-id`)

  const getGuestCartIdCookieName = () =>
    `${BASE_CART_COOKIE_NAME}market:${config.public.commerceLayer.COMMERCE_LAYER_MARKET}`

  const getLoggedCartIdCookieName = () =>
    `${LOGGED_CART_COOKIE_NAME}market:${config.public.commerceLayer.COMMERCE_LAYER_MARKET}`

  // CART
  const cart = useOrder("cart")

  const shippingInfos = useShippingInfos(cart.order)

  // COOKIES
  const saveCartId = () => {
    try {
      // Load current and dual cart ID to avoid swapping them during login/logout
      // Dual cart ID is the guest one if the user is logged in (happens during login)
      // or the logged one if the user is not logged in (happens during logout)
      const currentCartId = cart?.order?.value?.id
      const dualCartId = isLogged.value ? loadGuestCartId() : loadLoggedCartId()
      if (currentCartId && currentCartId != dualCartId) {
        const cartCookie = useStatefulCookie(
          isLogged.value
            ? getLoggedCartIdCookieName() /* logged cookie */
            : getGuestCartIdCookieName() /* guest cookie */,
          {
            maxAge: isLogged.value
              ? 24 * 60 * 60 /* 1 day */
              : 365 * 24 * 60 * 60 /* 1 year */
          }
        )
        cartCookie.value = currentCartId
      }
    } catch (err) {
      console.error(err)
    }
  }

  const loadGuestCartId: () => string | null | undefined = () => {
    const cartCookie = useStatefulCookie(getGuestCartIdCookieName())
    return cartCookie.value as string | null | undefined
  }

  const deleteGuestCartId: () => void = () => {
    const cartCookie = useStatefulCookie(getGuestCartIdCookieName())
    cartCookie.value = null
  }

  const loadLoggedCartId: () => string | null | undefined = () => {
    const cartCookie = useStatefulCookie(getLoggedCartIdCookieName())
    return cartCookie.value as string | null | undefined
  }

  const deleteLoggedCartId: () => void = () => {
    const cartCookie = useStatefulCookie(getLoggedCartIdCookieName())
    cartCookie.value = null
  }

  const loadCustomerCart = async () => {
    try {
      if (userId.value) {
        const [order] = await $cl.customers.orders(userId.value, {
          fields: ["id"],
          filters: { status_in: "draft,pending" },
          pageSize: 1,
          sort: { updated_at: "desc" }
        })

        const orderDetails = order
          ? await $cl.orders.retrieve(order.id, {
              include: ["line_items"]
            })
          : null

        return orderDetails
      }

      return null
    } catch {
      return null
    }
  }

  const loadCartId = async () => {
    const guestCartId = loadGuestCartId()
    let loggedCartId = loadLoggedCartId()

    if (!isLogged.value) {
      return guestCartId
    }

    if (loggedCartId) {
      // If a logged cart ID is already available refresh it in background
      loadCustomerCartId(guestCartId ?? undefined).then(
        async (updatedCartId: string | null | undefined) => {
          if (loggedCartId && !updatedCartId) {
            // If there's no cart in CL anymore, delete the current cart
            await resetCart()
            loggedCartId = null
          } else if (updatedCartId && updatedCartId !== loggedCartId) {
            // If the cart IDs are different, initialize the CL one
            cart.initOrder(updatedCartId)
            await cart.fetchOrder()
            cartInitialized.value = true
            // And store the ID in the cookie
            saveCartId()
          }
        }
      )
    } else {
      // If a logged cart ID is not available refresh it immediately
      loggedCartId = await loadCustomerCartId(guestCartId ?? undefined)
    }
    return loggedCartId
    //: loadGuestCartId()
  }

  const loadCustomerCartId = async (guestCartId?: string) => {
    const customerCart = await loadCustomerCart()

    if (!customerCart && !guestCartId) {
      return null
    } else if (guestCartId && !customerCart) {
      // aggiungere mail customer a guestCart e cancellare id cookie
      try {
        await $cl.orders.update({
          id: guestCartId,
          customer_email: user.value?.email
        })
        return guestCartId
      } catch (err) {
        // This may fail for several reasons, including guest order not editable anymore
        // In this case, we failover to create a new cart (and delete the guest cart ID)
        return null
      } finally {
        deleteGuestCartId()
      }
    } else if (!guestCartId && customerCart) {
      // return customer cart id
      return customerCart.id
    } else if (guestCartId && customerCart) {
      // do not modify order during checkout
      if (route?.path?.startsWith("/checkout")) {
        return customerCart.id
      }
      try {
        // fetch order dai cookie
        const guestOrder = await $cl.orders.retrieve(guestCartId, {
          include: ["line_items"]
        })
        // merge
        await mergeCarts(guestOrder, customerCart)
      } catch (err) {
        // This may fail for several reasons, including guest order not existing anymore
        // In this case, we failover to create a new cart (and delete the guest cart ID)
      } finally {
        // cancellare id cookie
        deleteGuestCartId()
      }
      // return customer cart id
      return customerCart.id
    }
  }

  const mergeCarts = async (guestCart: OrderCL, customerCart: OrderCL) => {
    const lineItemsToAdd =
      guestCart.line_items
        ?.filter((lineItem) => lineItem.item_type === "skus")
        ?.map((guestLineItem) => {
          // check se esiste uno sku code uguale tra i due ordini
          const customerCartLineItem = customerCart.line_items?.find(
            (customerLineItem) =>
              customerLineItem.sku_code === guestLineItem.sku_code
          )

          if (!customerCartLineItem) {
            return {
              sku_code: guestLineItem.sku_code!,
              quantity: guestLineItem.quantity,
              metadata: guestLineItem.metadata
            }
          }
        }) ?? []

    for (const lineItemToAdd of lineItemsToAdd) {
      if (!lineItemToAdd) continue

      await $cl.line_items.create({
        ...lineItemToAdd,
        item_type: "skus",
        _update_quantity: true,
        order: { id: customerCart.id, type: "orders" }
      })
    }
  }

  // INIT
  const initCart = async () => {
    const id = (await loadCartId()) ?? null
    cart.initOrder(id)
    await cart.fetchOrder()
    cartInitialized.value = true
    saveCartId()
  }

  // RESET
  const resetCart = () => {
    cart.initOrder(null)

    if (!isLogged.value) {
      deleteGuestCartId()
    } else {
      deleteLoggedCartId()
    }
  }

  // CART METHODS

  const createCartIfNotExisting = async () => {
    if (!cart.order.value) {
      // If cart hasn't been fetched yet, try to fetch it now
      await initCart()
      if (!cart.order.value) {
        // If cart isn't available yet, create a new one
        await cart.createOrder()
      }
    }
    saveCartId()
  }

  const refreshCart = async (forceRefresh = false) => {
    await cart.fetchOrder(forceRefresh)
  }

  type MyLineItemCreate = Omit<LineItemCreate, "quantity" | "order"> & {
    metadata: LineItemMetadata
  }

  const addToCart = async (
    attributes: MyLineItemCreate,
    quantity = 1,
    refresh = true
  ) => {
    await createCartIfNotExisting()

    const item = await cart.addLineItem(attributes, quantity, refresh)

    lastAdded.value = item!
    if (
      !addMoreModal.statusModal.value &&
      !preCheckoutModal.statusModal.value
    ) {
      if (md.value) {
        addToCartModalDesktop.value = true

        clearTimeout(timerId.value)

        timerId.value = setTimeout(
          () => (addToCartModalDesktop.value = false),
          5000
        )
      } else {
        addToCartModalMobile.openModal()
      }
    }

    return item
  }

  // const getLineItemAttributes = () => ({
  //   metadata: {},
  // })

  const checkEditableCart = cart.checkEditable

  const removeFromCart = cart.removeLineItem

  const removeFromCartBySku = cart.removeLineItemBySku

  const updateQuantity = cart.updateQuantity

  const updateQuantityBySku = cart.updateQuantityBySku

  const increaseQuantity = cart.increaseQuantity

  const decreaseQuantity = cart.decreaseQuantity

  const isInCart = cart.isInOrder

  const initCompare = cart.initCompare

  const removeFromSoldOutCompareData = cart.removeFromSoldOutCompareData
  const removeFromLimitedCompareData = cart.removeFromLimitedCompareData

  const applyCoupon = useApplyCouponFactory(
    () => cart.order.value?.customer_email ?? null,
    async (couponCode) => {
      await createCartIfNotExisting()
      await cart.updateOrder({
        coupon_code: couponCode.toUpperCase().trim()
      })
      discountCodes.value = await getDiscountCodes(
        isLogged.value,
        user.value,
        cart.order.value?.coupon_code
      )
    }
  )

  const totalAmoutOrder = computed(() => {
    const order = cart.order.value || {}
    const shippingMethodPrice =
      shippingInfos.shippingInfos.value?.shippingMethod?.price_amount_float || 0
    const isFreeShipping =
      shippingInfos.shippingInfos.value?.freeShipping || false

    const subtotalAmount = order.subtotal_amount_float || 0
    const discountAmount = order.discount_amount_float || 0
    const giftCardAmount = order.gift_card_amount_float || 0

    const totalAmount =
      subtotalAmount +
      discountAmount +
      giftCardAmount +
      (isFreeShipping ? 0 : shippingMethodPrice)

    return totalAmount
  })

  const applyGiftCard = async (giftCardCode: string) => {
    await cart.updateOrder({
      gift_card_code: giftCardCode
    })
  }

  // watch(userId, () => initCart())

  return {
    // CART
    cartId: cart.orderId,
    cart: cart.order,
    cartInitialized,
    soldOutCompareData: cart.soldOutCompareData,
    limitedCompareData: cart.limitedCompareData,
    priceChangedCompareData: cart.priceChangedCompareData,
    allOrderShipments: cart.allOrderShipments,
    lineItems: cart.lineItems,
    freeGiftPromotions: cart.freeGiftPromotions,
    promotions: cart.promotions,
    giftCards: cart.giftCards,
    shipments: cart.shipments,
    adjustments: cart.adjustments,
    totalAmoutOrder,
    addToCartModalDesktop,
    lastAdded,
    subtotalAmountFloatWithoutFreeGifts:
      cart.subtotalAmountFloatWithoutFreeGifts,
    discountAmountFloatWithoutFreeGifts:
      cart.discountAmountFloatWithoutFreeGifts,
    freeGiftPromotionsAmountFloat: cart.freeGiftPromotionsAmountFloat,

    // CART METHODS
    initCart,
    refreshCart,
    resetCart,
    addToCart,
    removeFromSoldOutCompareData,
    removeFromLimitedCompareData,
    checkEditableCart,

    // getLineItemAttributes,
    removeFromCart,
    removeFromCartBySku,
    updateQuantity,
    updateQuantityBySku,
    increaseQuantity,
    decreaseQuantity,
    isInCart,
    applyCoupon,
    applyGiftCard,
    // SHIPPING INFOS
    shippingInfos,

    initCompare,

    // USED WHEN LOGGING OUT
    deleteLoggedCartId
  }
}
