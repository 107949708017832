import { gql } from "@urql/core"
import { WrapperImageProps } from "~/components/Utils/WrapperImage.props"
import { WrapperImageFragment } from "~/types/cms"

export const fragment = gql`
  fragment WrapperImage on WrapperImage {
    focalPoint
    altText
    base64
    image {
      url
      width
      height
      fileName
    }
  }
`

export const connect = (
  data?: WrapperImageFragment | null
): WrapperImageProps => {
  if (!data) {
    return {
      focalPoint: {
        x: 0.5,
        y: 0.5,
      },
      altText: "Fallback Image",
      base64:
        "data:image/jpeg;base64,/9j/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCAAJABADASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAX/xAAcEAEAAgEFAAAAAAAAAAAAAAABAAIyAxESFCP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oADAMBAAIRAxEAPwB5Z5i2GO7HnsJI2llFXwipH//Z",
      image: {
        url: "/i5oh5hzamkwo/sh7iPFxS7gk5lDN6y6tf7/79055388b267a1a6688b1f70d46888a1/DefaultBrandImage.webp",
        width: 300,
        height: 300,
      },
    }
  }
  return {
    focalPoint: data?.focalPoint?.focalPoint ?? null,
    activateFocalPoint: !!data?.focalPoint?.focalPoint,
    altText: data?.altText ?? data?.image?.fileName ?? "",
    base64: data.base64 ?? "",
    image: {
      url: data.image?.url ?? "",
      height: data.image?.height ?? 0,
      width: data.image?.width ?? 0,
    },
  }
}
