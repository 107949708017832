import type { ProductBrandPageContent } from "~/types/Algolia"
import { VerticalProps } from "~/components/ProductTile/Overall/Vertical.props"
import { BundleProductCardProps } from "~/components/ProductPage/Utils/BundleProductCard.props"
import { ResultsProps } from "~/components/ProductTile/Overall/Results.props"
import { WrapperImageProps } from "~/components/Utils/WrapperImage.props"
import { Theme } from "~/components/Badge/Product.props"
import { BrandProps } from "~/components/Card/Brand.props"
import { connect as connectWrapperImage } from "~/connectors/fragments/WrapperImage"
// TODO: remove stories when not needed anymore
import { Selectable as BundleCard } from "~/components/ProductPage/Utils/BundleProductCard.stories"
import { discountPercentage } from "~/utils/formatters"

type Price = {
  compare_at_amount_float: number
  amount_float: number
  discountPercentage: string
  pricePerUnit: number
}

type Unit = {
  type: string
  typeuon: string
}

type Promotion = {
  theme: Theme | string
  text?: string | undefined
}

type Ratings = {
  reviews: number
  rating: number
}

type Category = {
  name?: string
  slug?: string
}

export type ProductAlgolia = {
  productCode: string
  shortDescription: string
  mainImage: WrapperImageProps
  productName: string
  slug: string
  price: Price | null
  brand: object
  categories?: {
    lvl0?: Category[]
    lvl1?: Category[]
    lvl2?: Category[]
    lvl3?: Category[]
    lvl4?: Category[]
  }
  mainCategory: {
    name: string
    slug: string
  }
  inStock: boolean
  sellabilityStatus?: string
  isDiscounted: boolean
  deductibleProduct: boolean
  commercialTags: string[]
  activeSubstance: string
  __queryID?: string
  unit?: Unit
  ratings?: Ratings
  expirationDate: string
  genericDrug: boolean
  correspondingGenericEquivalentDrugs: ProductAlgolia[]
}

// @ts-ignore
export const mapProducts: VerticalProps[] | ResultsProps[] | any = (
  items: ProductAlgolia[] | any,
  queryID: string | null = null,
  type: "ALGOLIA" | "CONTENTFUL"
) => {
  switch (type) {
    case "ALGOLIA":
      return items
        .filter((item: ProductAlgolia) => {
          // TF-2086 - filtering free gifts via lack of productCode
          return item.productCode
        })
        .map((item: ProductAlgolia, index: number) => {
          let promotionsProduct: Promotion[] =
            item?.commercialTags && item?.commercialTags.length > 0
              ? item?.commercialTags.map((badge: string) => ({
                  theme: badge,
                  text: ""
                }))
              : []

          const discount = discountPercentage(
            item.price.amount_float,
            item.price.compare_at_amount_float
          )
          if (discount > 0)
            promotionsProduct.push({
              theme: "SCONTO",
              text: `-${discount}%`
            })
          return {
            productCode: item.productCode,
            productImage: item.mainImage,
            title: item.productName,
            slug: item.slug,
            path: "/p-" + item.slug,
            reviews: item?.ratings?.reviews,
            rating: item?.ratings?.rating,
            oldPrice: item?.price?.compare_at_amount_float
              ? Number(item.price.compare_at_amount_float)
              : 0,
            currentPrice: item?.price?.amount_float
              ? Number(item.price.amount_float)
              : 0,
            promotions: promotionsProduct,
            pricePerUnit: item?.price?.pricePerUnit,
            inStock: item?.inStock,
            sellabilityStatus: item.sellabilityStatus ?? "sellable",
            fullWidth: true,
            unitType: item?.unit?.type,
            queryID: item.__queryID ? item.__queryID : queryID ? queryID : "",
            position: index + 1,
            mainCategoryName: item?.mainCategory?.name,
            categories: {
              main: item?.categories?.lvl0
                ? item?.categories?.lvl0[0].name
                : undefined,
              second: item?.categories?.lvl1
                ? item?.categories?.lvl1[0].name
                : undefined,
              third: item?.categories?.lvl2
                ? item?.categories?.lvl2[0].name
                : undefined,
              fourth: item?.categories?.lvl3
                ? item?.categories?.lvl3[0].name
                : undefined,
              fifth: item?.categories?.lvl4
                ? item?.categories?.lvl4[0].name
                : undefined
            },
            expirationDate: item.expirationDate,
            brand: {
              slug: item?.brand?.slug ?? undefined,
              name: item?.brand?.name ?? ""
            },
            isDeductable: item.deductibleProduct,
            discount:
              item?.price?.compare_at_amount_float && item?.price?.amount_float
                ? item?.price?.amount_float -
                  item?.price?.compare_at_amount_float
                : 0
          }
        })
      break

    case "CONTENTFUL":
      return items?.map((item: any) => {
        let promotionsProduct =
          item?.commercialTags && item?.commercialTags.length > 0
            ? item?.commercialTags.map((badge: string) => ({
                theme: badge,
                text: ""
              }))
            : []
        item?.price?.discountPercentage &&
        parseInt(`${item?.price?.discountPercentage}`) > 0
          ? promotionsProduct.push({
              theme: "SCONTO",
              text: `-${item?.price?.discountPercentage}%`
            })
          : null

        // TODO: da rendere asincrono
        // const getTotalReviewsAverages = $fetch(
        //   `/api/feedaty/getReviewsAverages/${item.productCode}`,
        //   {
        //     method: "GET"
        //   }
        // )

        const ratings = {
          // reviews: getTotalReviewsAverages?.Data?.Products?.RatingsCount ?? 0,
          // rating: getTotalReviewsAverages?.Data?.Products?.AverageValue ?? 0
          reviews: 0,
          rating: 0
        }

        return {
          productCode: item.productCode,
          productImage: item.mainImage,
          title: item.productName,
          path: "/p-" + item.slug,
          reviews: ratings.reviews,
          rating: ratings.rating,
          oldPrice: item?.price?.compare_at_amount_float ?? 0,
          currentPrice: item?.price?.amount_float,
          promotions: promotionsProduct,
          pricePerUnit: item?.price?.pricePerUnit,
          inStock: item.inStock,
          fullWidth: true,
          unitType: item.quantityUom,
          expirationDate: item.expirationDate,
          isDeductable: item.deductibleProduct,
          discount:
            item?.price?.compare_at_amount_float && item?.price?.amount_float
              ? item?.price?.amount_float - item?.price?.compare_at_amount_float
              : 0
        }
      })
      break
  }
}

export const mapProductsAlgolia = (products: ProductAlgolia[]) =>
  mapProducts(products, null, "ALGOLIA")
export const mapProductsResult = (products: ProductAlgolia[]) =>
  mapProducts(products, null, "RESULT")

export const mapBrands = (items: ProductBrandPageContent[]) =>
  items.map(
    (item): BrandProps => ({
      logoImage: connectWrapperImage(item.image),
      path: `b-${item.slug}`,
      title: item.brandName,
      description: item.description
    })
  )

// TODO: make it dynamic
export const mapBundle: BundleProductCardProps[] = (items: any[]) => {
  const bundle: BundleProductCardProps[] = items.map((item) => ({
    ...BundleCard.args
  }))

  return bundle
}

export const mapResults = (items: any[]) => {
  const results = items.map((item) => ({
    productImage: item.mainImage,
    title: item.productName,
    path: "/prodotti/" + item.slug,
    reviews: 33,
    rating: 5,
    currentPrice: item.price.amount_float,
    oldPrice: item.price.compare_at_amount_float ?? 0,
    unitType: item?.unit?.type,
    pricePerUnit: 1,
    isPromo: false,
    discount: false,
    inStock: true
    //availableIn: number,
    //lastItems: boolean,
    //showQuantity: boolean,
    //userLogged: boolean,
    //fullWidth: boolean,
    //listingLayout: boolean,
    //promotions: ProductProps[],
  }))

  return results
}

export const smallBanner = (items: any[]) => {
  const banner = items.map((item) => ({
    productImage: item.mainImage

    // brandImage: WrapperImageProps
    // brandTitle: string
    // cta: ButtonProps
    // title: string
    // card: ResultsProps
    // theme?: "white" | "green-10" | "orange-10"
    // isCardFirst?: boolean
    // isAlwaysVertical?: boolean
  }))

  return banner
}
