import type { RouterConfig } from "@nuxt/schema"
import qs from "qs"

// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
  parseQuery: qs.parse,
  stringifyQuery: qs.stringify,
  scrollBehavior(to, _, savedPosition) {
    const nuxtApp = useNuxtApp()

    // If history back
    if (savedPosition) {
      // Handle Suspense resolution
      return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce("page:finish", () => {
          setTimeout(() => resolve(savedPosition), 50)
        })
      })
    }
    // Scroll to heading on click

    if (to.hash) {
      setTimeout(() => {
        const heading = document.querySelector(to.hash) as any
        return (
          heading &&
          window.scrollTo({
            top: heading.offsetTop,
            behavior: "smooth"
          })
        )
      }, 500)
      return
    }

    // Scroll to top of window
    nuxtApp.hooks.hookOnce("page:finish", () => {
      return window.scrollTo({
        top: 0
      })
    })
  }
}
