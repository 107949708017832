import { ResponseValidateCoupon } from "../server/api/coupons/validateCoupon"

export default (
  getEmail: () => string | null,
  applyCouponFn: (coupon: string) => Promise<void>
) => {
  const isLogged = useIsLogged()

  const applyCoupon = async (couponCode: string) => {
    try {
      if (couponCode && couponCode !== "") {
        const checkApplyCouponCode: ResponseValidateCoupon = await $fetch(
          `/api/coupons/validateCoupon`,
          {
            method: "POST",
            headers: isLogged.value
              ? [["Authorization", `Bearer ${isLogged.value}`]]
              : [],
            body: {
              couponCode: couponCode.trim(),
              customerEmail: getEmail()
            }
          }
        )

        const couponValid = checkApplyCouponCode.coupon_valid ?? false
        const couponSpent = checkApplyCouponCode.coupon_spent ?? true

        if (!couponValid) {
          throw createError({
            statusCode: 400,
            message: `Coupon code not valid yet`
          })
        }

        if (couponSpent) {
          throw createError({
            statusCode: 400,
            message: `Coupon code already spent`
          })
        }
      }
      await applyCouponFn(couponCode)
    } catch (error) {
      throw createError({
        statusCode: 500,
        message: `Coupon code not valid yet..`
      })
    }
  }

  return applyCoupon
}
