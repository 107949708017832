export const utils = {
  "typography": () => ({
      ...Object.fromEntries(
        Object.keys(_tokens.typography).map((value) => [
        `.${value}`,
        {
          fontFamily: `{typography.${value}.sm.fontFamily}`,
          fontSize: `{typography.${value}.sm.fontSize}`,
          lineHeight: `{typography.${value}.sm.lineHeight}`,
          letterSpacing: `{typography.${value}.sm.letterSpacing}`,
          fontWeight: `{typography.${value}.sm.fontWeight}`,

          "@c_lg": {
            fontFamily: `{typography.${value}.lg.fontFamily}`,
            fontSize: `{typography.${value}.lg.fontSize}`,
            lineHeight: `{typography.${value}.lg.lineHeight}`,
            letterSpacing: `{typography.${value}.lg.letterSpacing}`,
            fontWeight: `{typography.${value}.lg.fontWeight}`
          }
        }]
        )
      ),
      ...Object.fromEntries(
        Object.keys(_tokens.typography).map((value) => [
        `.c_md\\:${value}`,
        {
          "@c_md": {
            fontFamily: `{typography.${value}.sm.fontFamily}`,
            fontSize: `{typography.${value}.sm.fontSize}`,
            lineHeight: `{typography.${value}.sm.lineHeight}`,
            letterSpacing: `{typography.${value}.sm.letterSpacing}`,
            fontWeight: `{typography.${value}.sm.fontWeight}`
          },

          "@c_lg": {
            fontFamily: `{typography.${value}.lg.fontFamily}`,
            fontSize: `{typography.${value}.lg.fontSize}`,
            lineHeight: `{typography.${value}.lg.lineHeight}`,
            letterSpacing: `{typography.${value}.lg.letterSpacing}`,
            fontWeight: `{typography.${value}.lg.fontWeight}`
          }
        }]
        )
      ),
      ...Object.fromEntries(
        Object.keys(_tokens.typography).map((value) => [
        `.c_lg\\:${value}`,
        {
          "@c_lg": {
            fontFamily: `{typography.${value}.lg.fontFamily}`,
            fontSize: `{typography.${value}.lg.fontSize}`,
            lineHeight: `{typography.${value}.lg.lineHeight}`,
            letterSpacing: `{typography.${value}.lg.letterSpacing}`,
            fontWeight: `{typography.${value}.lg.fontWeight}`
          }
        }]
        )
      )
    }),
} as const

export type GeneratedPinceauUtils = typeof utils

export default utils