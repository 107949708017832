import type { NotificationAlertProps as Notification } from "~/components/NotificationAlert.props"
import { pushDataLayer } from "~/utils/analytics/analytics"

export default () => {
  const { addNotification } = useNotification()
  const skuState = useState("product-unavailable-state")
  const emailState = useState("notification-subscription-mail")
  const inStockSubscriptionItem = useState<any>("inStockSubscription")

  const notificationError: Notification = {
    id: Math.random(),
    type: "warning",
    notificationAlertText: "Richiesta già inviata",
    isWithCloseIcon: true
  }
  const notificationSuccess: Notification = {
    id: Math.random(),
    type: "success",
    notificationAlertText:
      "Hai inviato la richiesta. **Ti avviseremo** quando il prodotto tornerà disponibile.",
    isWithCloseIcon: true
  }

  const sendGtmEvent = () => {
    pushDataLayer({
      ...inStockSubscriptionItem.value
    })
  }

  const sendSubscription = async () => {
    const { existingSubscription } = await $fetch("/api/inStockSubscription", {
      method: "POST",
      body: {
        email: emailState.value,
        productIds: isArray(skuState.value) ? skuState.value : [skuState.value]
      }
    })

    if (existingSubscription) {
      addNotification(notificationError)
    } else {
      addNotification(notificationSuccess)
    }

    sendGtmEvent()
  }

  return {
    inStockSubscriptionItem,
    sendSubscription
  }
}
