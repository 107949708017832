import { createCommerceLayerClient, isValid } from "~/config/commercelayer"
import { createStorage } from "unstorage"
const storage = createStorage()

export default defineNuxtPlugin({
  name: "commercelayer",
  enforce: process.server ? "pre" : "post",
  async setup(nuxtApp) {
    const { commerceLayerToken: guestCommerceLayerToken } =
      useSessionGuestManager()

    const runtimeConfig = useRuntimeConfig().public.commerceLayer
    const organization = runtimeConfig.COMMERCE_LAYER_ORGANIZATION

    const fetchGuestToken = async () => {
      const { token } = await $fetch("/api/getCLToken", {
        method: "POST"
      })
      return token
    }

    const fetchServerGuestToken = async () => {
      const cachedToken = await storage.getItem<string>(
        "commerceLayer:serverToken"
      )
      if (cachedToken && isValid(cachedToken)) return cachedToken
      const token = await fetchGuestToken()
      await storage.setItem("commerceLayer:serverToken", token)
      return token
    }

    const fetchClientGuestToken = async () => {
      const tokenInCookies = guestCommerceLayerToken.value
      if (tokenInCookies && isValid(tokenInCookies)) return tokenInCookies
      const token = await fetchGuestToken()
      guestCommerceLayerToken.value = token
      return token
    }

    const isLogged = await useCheckSessionUser(false)

    const auth = isLogged
      ? {
          getToken() {
            return useStatefulCookie("cmlToken").value
          },
          async refreshToken() {
            await useCheckSessionUser(false, true)
            return useStatefulCookie("cmlToken").value
          }
        }
      : process.server
      ? {
          token: await fetchServerGuestToken(),
          getToken() {
            return this.token
          },
          async refreshToken() {
            const token = await fetchServerGuestToken()
            this.token = token
            return token
          }
        }
      : {
          token: await fetchClientGuestToken(),
          getToken() {
            return this.token
          },
          async refreshToken() {
            const token = await fetchClientGuestToken()
            this.token = token
            return token
          }
        }

    const cl = createCommerceLayerClient(organization, auth)

    try {
      if (process.client && nuxtApp._route.name === "checkout-id") {
        const orderId = nuxtApp._route.params.id as string
        const order = await cl.orders.retrieve(orderId, {
          include: ["payment_method", "payment_source"]
        })

        if (
          order?.status === "pending" &&
          order.payment_method?.payment_source_type === "axerve_payments" &&
          order.payment_source
        ) {
          await cl.orders.update({
            id: order.id,
            _place: true
          })
        }
      }
    } catch {}

    return {
      provide: {
        cl
      }
    }
  }
})
