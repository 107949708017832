import type { Order, Sku, CommerceLayerClient } from "@commercelayer/sdk"

const chunk = (arr: unknown[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )

export const addPrices = async ($cl: CommerceLayerClient, order: Order) => {
  const skuCodes =
    order.line_items
      ?.filter((l) => l?.item?.type === "skus")
      ?.map((l) => l.sku_code!) ?? []

  let allSkus: Sku[] = []

  for (const skuCodesChunk of chunk(skuCodes, 10)) {
    const skus =
      skuCodesChunk.length > 0
        ? await $cl.skus.list({
            ...skusQuery,
            filters: { code_in: skuCodesChunk.join(",") }
          })
        : []

    allSkus.push(...skus)
  }

  return {
    ...order,
    line_items: order.line_items?.map((l) => ({
      ...l,
      item:
        l.item_type !== "skus"
          ? l.item
          : ({
              ...l.item,
              prices: allSkus?.find((s) => s.code === l.sku_code)?.prices
            } as Sku)
    }))
  }
}
