import { evaluateRedirect } from "~/.nuxt/nuxt-redirects/redirectsMiddleware"
import { removePageFromUrl } from "../utils/url"
import qs from "qs"

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.client) return

  const isPaginationPage =
    to.name === "ricerca" ||
    to.name === "ricerca-page-page" ||
    to.name === "c-category" ||
    to.name === "c-category-page-page" ||
    to.name === "b-brand" ||
    to.name === "b-brand-page-page" ||
    to.name === "generic" ||
    to.name === "generic-page-page" ||
    to.name === "piu-acquistati" ||
    to.name === "piu-acquistati-page-page" ||
    to.name === "enciclopedia-category" ||
    to.name === "enciclopedia-category-page-page" ||
    to.name === "promozioni-brand-slug" ||
    to.name === "promozioni-brand-slug-page-page" ||
    to.name === "promozioni-interne-slug" ||
    to.name === "promozioni-interne-slug-page-page" ||
    to.name === "top-magazine-category" ||
    to.name === "top-magazine-category-page-page" ||
    to.name === "top-magazine-tutti-i-post" ||
    to.name === "top-magazine-tutti-i-post-page-page"

  if (!isPaginationPage) return

  const { query, path, params, name } = to
  const pageValue = query?.page ?? false
  const redirectedValue = query?.redirected ?? false
  const pageParam = params?.page ?? false
  const qValue = query?.q ?? false

  if (pageValue === pageParam) return

  if (pageValue) {
    const pathWithoutPage = await removePageFromUrl(path)
    const { page, q, redirected, ...queryTmp } = query
    const newFullPath = qs.stringify(queryTmp)
    const furtherRedirect = await evaluateRedirect(pathWithoutPage)
    let fullPath: string
    if (qValue) {
      fullPath = `${furtherRedirect?.uri || pathWithoutPage}${
        newFullPath ? "?" + newFullPath : ""
      }`
    } else {
      fullPath = `${furtherRedirect?.uri || pathWithoutPage}/page/${pageValue}${
        newFullPath ? "?" + newFullPath : ""
      }`
    }
    return navigateTo(fullPath!, {
      redirectCode: 301,
      external: true
    })
  }
  if (qValue) {
    const pathWithoutPage = await removePageFromUrl(path)
    const { page, q, redirected, ...queryTmp } = query
    const newFullPath = qs.stringify(queryTmp)
    const furtherRedirect = await evaluateRedirect(pathWithoutPage)
    const fullPath = `${furtherRedirect?.uri || pathWithoutPage}${
      newFullPath ? "?" + newFullPath : ""
    }`
    return navigateTo(fullPath, {
      redirectCode: 301,
      external: true
    })
  }
  if (redirectedValue) {
    const { page, redirected, ...queryTmp } = query
    const newFullPath = qs.stringify(queryTmp)
    const fullPath = `${path}${newFullPath ? "?" + newFullPath : ""}`
    return navigateTo(fullPath, {
      redirectCode: 301,
      external: true
    })
  }
})
