export const splitFullName = (fullName: string) => {
  // This split it's a request from client

  const trimmedFullName = fullName.trim()
  const splitFullName = trimmedFullName.split(" ")
  const firstName = splitFullName[0]
  const lastName = trimmedFullName.substring(trimmedFullName.indexOf(" ") + 1)

  return {
    firstName,
    lastName
  }
}
