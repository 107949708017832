import { WrapperImageProps } from "~/components/Utils/WrapperImage.props"
import { screensObj } from "./breakpoints"
import { cloneDeep } from "lodash"

export const getImageData = ({
  altText,
  url,
  width,
  height,
  fp,
  base,
  preload,
  imgAttrs
}: {
  altText?: string
  url: string
  width?: number
  height?: number
  fp?: { x: number; y: number }
  base?: string
  preload?: boolean
  imgAttrs?: object
}): WrapperImageProps => ({
  image: {
    url,
    width,
    height
  },
  altText: altText || "Placeholder",
  fit: "cover",
  focalPoint: fp
    ? {
        x: fp.x,
        y: fp.y
      }
    : {
        x: 0.5,
        y: 0.5
      },
  base64: base
    ? base
    : "data:image/jpeg;base64,/9j/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCAAJABADASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAX/xAAcEAEAAgEFAAAAAAAAAAAAAAABAAIyAxESFCP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oADAMBAAIRAxEAPwB5Z5i2GO7HnsJI2llFXwipH//Z",
  sizes: "xs:320px md:414px lg:768px xl:1024px",
  screens: screensObj,
  preload: preload,
  imgAttrs: imgAttrs
})

export const bgLoginImage: WrapperImageProps = getImageData({
  altText: "background image",
  url: "/i5oh5hzamkwo/7730ovBHviXrW6g652dY4Y/1c17692b6d4987f21672938ed40c1316/bg-login.svg",
  width: 1500,
  height: 500
})

export const passwordImage: WrapperImageProps = getImageData({
  altText: "locker image",
  url: "/i5oh5hzamkwo/2o5OqQJzRKo4fimw3ZxVr5/63be08cf38e919ea4cb975fd8d406631/locker.png",
  width: 720,
  height: 240
})

export const passwordConfirmationImage: WrapperImageProps = getImageData({
  altText: "password confirmation image",
  url: "/i5oh5hzamkwo/z26bOH2uHtMfiCshqzGIH/1757341d96ecd599a072660a07095faf/passwordConfirmation.png",
  width: 720,
  height: 240
})

/**
 * Return new WrapperImageProps which is the sum of imageProps and additionalProps
 * @param imageProps
 * @param additionalProps
 * @returns
 */
export const setAdditionalImageProps = (
  imageProps: WrapperImageProps,
  additionalProps: Partial<WrapperImageProps>
): WrapperImageProps => {
  const newProps = { ...cloneDeep(imageProps), ...additionalProps }
  return newProps
}

/**
 * Return new image attributes which is the sum of imageAttrs and additionalAttrs
 * @param imageAttrs
 * @param additionalAttrs
 * @returns
 */
export const setAdditionalImageAttrs = (
  imageAttrs: object = {},
  additionalAttrs: object
): object => {
  const newAttrs = { ...cloneDeep(imageAttrs), ...additionalAttrs }
  return newAttrs
}
