import { acceptHMRUpdate, defineStore } from "pinia"
import { useJwt } from "@vueuse/integrations/useJwt"
import aa from "search-insights"
import { fetchNewsletterOnce } from "~/utils/newsletter"
import {
  customerStatus,
  loginMethod,
  socialLoginStorageKey
} from "~/utils/analytics/customer"
import { pushSignInNewsletterGenerateLeadEvent } from "~/utils/analytics/generateLead"

const { sendEventLogin, sendEventSignUp } = useGA4SiteEvents()

export type DataUser = {
  name: string
  surname: string
  birthday: string
  fiscalCode: string
  phone: string
  email: string
  isSubscribed: boolean
  hasPreviousCoupons: boolean //returns true if the user has already subscribed to the newsletter before
  defaultAddress?: string
  status: customerStatus
}

export const useUserStore = defineStore("user", () => {
  const user = ref<DataUser | null>(null)
  const userId = ref("")
  const sessionChecked = ref(false)
  const registrationCookie = useStatefulCookie("show-registration-modal", {
    default: () => undefined
  })

  const { closeModal } = useModal()

  const login = async (fields: { email: string; password: string }) => {
    return await $fetch("/api/account/login", {
      method: "POST",
      body: {
        username: fields.email,
        password: fields.password
      }
    })
      .then(async (responseLogin: any) => {
        return await $fetch("/api/account/jwtCommercelayer", {
          method: "post",
          body: {
            customerEmail: fields.email,
            authToken: responseLogin.authToken
          }
        })
          .then(async (responseCommerceLayerToken: any) => {
            const { payload: payloadCommerceLayerToken }: any = useJwt(
              responseCommerceLayerToken.jwtToken
            )

            const UserID = payloadCommerceLayerToken.value.owner.id
            if (UserID) {
              userId.value = UserID
              aa("setUserToken", UserID)
            }
            await updateNewsletterSubscription(responseLogin.authToken)

            sendEventLogin(loginMethod.default)

            return {
              authToken: responseLogin.authToken,
              refreshToken: responseLogin.refreshToken,
              commerceLayerToken: responseCommerceLayerToken.jwtToken
            }
          })
          .catch((error) => {
            return "Token non recuperato"
          })
      })
      .catch((error) => {
        switch (error.data.data.errorCode) {
          case "02":
            return "Credenziali errate. Ritenta!"

          case "04":
            return "Utente non trovato..."
          //User is coming from old site and have an illegal password, resetting the password is mandatory
          case "06":
            return "reset_password"
          default:
            return "Credenziali errate. Ritenta!"
        }
      })
  }

  const socialLogin = async (code: string) => {
    return await $fetch(`/api/account/socialLogin/${code}`, {
      method: "POST"
    })
      .then(async (responseLogin: any) => {
        const respLogin = JSON.parse(responseLogin)

        const { payload: payloadAccessToken }: any = useJwt(
          respLogin.access_token
        )

        let difference: number

        try {
          const { payload: payloadIdToken }: any = useJwt(respLogin.id_token)

          const dataCreated =
            parseInt(payloadIdToken.value.identities[0].dateCreated) / 1000

          const authTime = payloadIdToken.value.auth_time
          difference = (authTime - dataCreated) / 60

          if (difference <= 1) {
            registrationCookie.value = true
            sendEventSignUp(localStorage.getItem(socialLoginStorageKey) ?? "")
          } else {
            sendEventLogin(localStorage.getItem(socialLoginStorageKey) ?? "")
          }
          localStorage.removeItem(socialLoginStorageKey)
        } catch (error) {
          console.log(error)
        }

        return await $fetch("/api/account/jwtCommercelayer", {
          method: "post",
          body: {
            customerEmail: payloadAccessToken.value.username,
            authToken: respLogin.access_token
          }
        })
          .then(async (responseCommerceLayerToken: any) => {
            const { payload: payloadCommerceLayerToken }: any = useJwt(
              responseCommerceLayerToken.jwtToken
            )

            const UserID = payloadCommerceLayerToken.value.owner.id
            if (UserID) {
              userId.value = UserID
              aa("setUserToken", UserID)
            }
            await updateNewsletterSubscription(respLogin.access_token)

            return {
              authToken: respLogin.access_token,
              refreshToken: respLogin.refresh_token,
              commerceLayerToken: responseCommerceLayerToken.jwtToken
            }
          })
          .catch((error) => {
            return "Token non recuperato"
          })
      })
      .catch((error) => {
        console.log(error)
        return "ERRORE DEL SISTEMA.."
      })
  }

  const signup = async (fields: {
    email: string
    password: string
    subscribeNewsletter?: boolean
  }) => {
    return await $fetch("/api/account/signup", {
      method: "POST",
      body: {
        username: fields.email,
        password: fields.password,
        newsletter: fields.subscribeNewsletter
      }
    })
      .then(async (responseSingUp: any) => {
        pushSignInNewsletterGenerateLeadEvent()

        return await $fetch("/api/account/jwtCommercelayer", {
          method: "post",
          body: {
            customerEmail: fields.email,
            authToken: responseSingUp.authToken
          }
        })
          .then(async (responseCommerceLayerToken: any) => {
            const { payload: payloadCommerceLayerToken }: any = useJwt(
              responseCommerceLayerToken.jwtToken
            )
            const UserID = payloadCommerceLayerToken.value.owner.id

            if (UserID) {
              userId.value = UserID
              aa("setUserToken", UserID)
            }

            sendEventSignUp(loginMethod.default)

            registrationCookie.value = true

            return {
              authToken: responseSingUp.authToken,
              refreshToken: responseSingUp.refreshToken,
              commerceLayerToken: responseCommerceLayerToken.jwtToken
            }
          })
          .catch(() => {
            return "Token non recuperato"
          })
      })
      .catch(() => {
        return "Errore durante la registrazione"
      })
  }

  const updatePassword = async (fields: {
    username: string
    password: string
    accessCode: string
  }) => {
    return await $fetch(`/api/account/edit-password/${fields.accessCode}`, {
      method: "POST",
      body: {
        username: fields.username,
        password: fields.password
      }
    })
      .then(async () => {
        return true
      })
      .catch(() => {
        return "Errore durante l'aggiornamento della password"
      })
  }

  const logout = () => {
    user.value = null
    userId.value = ""
    if (process.client) {
      if (location) {
        location.replace(customPaths.homepage)
      }
    }
  }

  const setUserId = (id: string) => {
    userId.value = id
  }

  const checkSubscriptionNewsletter = async (authToken: string) => {
    return await fetchNewsletterOnce(authToken)
  }

  const updateUserSubscriptionNewsletter = async (
    authToken: string,
    subscribe: boolean
  ) => {
    return await useFetch(`/api/account/updateNewsletter/${authToken}`, {
      method: "PATCH",
      body: {
        newsletter: subscribe
      }
    })
  }

  const updateNewsletterSubscription = async (authToken: string) => {
    const { isSubscribed, hasPreviousCoupons } =
      await checkSubscriptionNewsletter(authToken)

    user.value = {
      ...user.value,
      isSubscribed,
      hasPreviousCoupons
    }
  }

  const optimisticUpdateNewsletterSubscription = (
    subscribed: boolean,
    previousCoupons: boolean
  ) => {
    user.value = {
      ...user.value,
      isSubscribed: subscribed,
      hasPreviousCoupons: previousCoupons
    }
  }

  const updateUserData = async (dataUser: object) => {
    if (dataUser.metadata) {
      user.value = {
        ...user.value,
        ...dataUser.metadata,
        email: dataUser.email,
        status: dataUser.status
      }
    }
  }

  return {
    user,
    userId,
    sessionChecked,
    login,
    socialLogin,
    logout,
    signup,
    updatePassword,
    setUserId,
    updateUserData,
    updateNewsletterSubscription,
    updateUserSubscriptionNewsletter,
    optimisticUpdateNewsletterSubscription
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
