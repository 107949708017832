<script setup lang="ts">
import type { IconProps } from "./Icon.props"
const props = defineProps<IconProps>()
const src = computed(
  () => `/icons/${props.color ? props.color + "/" : ""}${props.name}`
)
if (props.preload) {
  useHead({
    link: [
      {
        rel: "preload",
        as: "image",
        href: src.value,
        key: src.value
      }
    ]
  })
}
</script>

<template>
  <img
    :src="src"
    :decoding="decoding"
    :fetchpriority="fetchPriority"
    :loading="fetchPriority !== 'high' ? 'lazy' : undefined"
    :title="titleText"
    :alt="altText"
  />
</template>
