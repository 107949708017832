export const searchAction = {
  brands: "marche",
  symptoms: "sintomi"
}

export const searchMethod = {
  query: "query",
  recent: "recenti",
  popular: "popolari",
  suggestions: "suggerimenti"
}

export const searchActionStorageKey = "search-action"
export const searchMethodStorageKey = "search-method"

export const debounceTimeout = 400

export const setSearchMethod = (method: string, action?: string) => {
  localStorage.setItem(searchMethodStorageKey, method)
  localStorage.setItem(
    searchActionStorageKey,
    action ? action : getSearchAction()
  )
}

export const getSearchAction = () => {
  return localStorage.getItem(searchActionStorageKey)
}

export const getSearchMethod = () => {
  return localStorage.getItem(searchMethodStorageKey)
}

export const clearSearchMethod = () => {
  localStorage.removeItem(searchMethodStorageKey)
  localStorage.removeItem(searchActionStorageKey)
}
