<script setup lang="ts">
import type { ProductProps, Theme } from "./Product.props"

const props = defineProps<ProductProps>()

type ThemeMap = {
  [key in Theme]: string
}

const themeMap: ThemeMap = {
  SCONTO: "bg-strawberry-main",
  PROMO: "bg-orange-main",
  "FUORI TUTTO": "bg-tiffany-main",
  "BLACK FRIDAY": "bg-black-main",
  "ULTIMI PEZZI": "bg-slate-800",
  NOVITÀ: "bg-slate-80"
}
</script>

<template>
  <span
    class="
      badge-product
      snail-bold
      md:mouse-bold
      whitespace-nowrap
      rounded
      p-1
      uppercase
      text-white
      md:px-2
      lg:py-[2px]
    "
    :class="[
      themeMap[theme],
      { 'order-first': themeMap[theme] === 'bg-strawberry-main' }
    ]"
  >
    {{ text ? text : theme }}
  </span>
</template>
