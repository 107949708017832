/**
 * Formats a number as currency using the Italian (IT) locale and the Euro (EUR) currency.
 *
 * @param {number} value - The value to be formatted as currency
 * @returns {string} The formatted currency string
 */
export const formatMoney = (value: number): string => {
  // FIXME: [TF-2107] if value is string the function return NaN
  if (typeof value !== undefined) {
    // Create a new number formatter for the Italian (IT) locale and the Euro (EUR) currency
    const formatter = new Intl.NumberFormat("it-IT", {
      currency: "EUR",
      style: "currency"
    })

    // Format the value as currency and return the result
    return formatter.format(value)
  } else {
    return "0,0 €"
  }
}

/**
 * Returns the integer representation of a value.
 * If the value is a string representation of an integer, it is parsed to an integer.
 * If the value is undefined, 0 is returned.
 * If the value is a negative number or 0, 0 is returned.
 *
 * @param {number | string | undefined} value - The value to be converted to an integer
 * @returns {number} The non-negative integer representation of the value, or 0 if the input is not a valid string representation of an integer or if the value is negative or 0
 */
export const getInt = (value: number | string | undefined): number => {
  // Return 0 if value is undefined
  if (typeof value === "undefined") return 0

  // Check if value is a string representation of an integer and parse it to an integer
  let myInt: number
  if (typeof value === "string") {
    const intValue = parseInt(value)
    if (isNaN(intValue)) {
      return 0
    }
    myInt = intValue
  } else {
    myInt = value
  }

  // Return 0 if myInt is less than or equal to 0
  return myInt <= 0 ? 0 : myInt
}

/**
 * Calculates the discount percentage of a product based on its current and original prices.
 *
 * @param {number} price - The current price of the product.
 * @param {number} [oldPrice] - The original price of the product. If not provided or is less than or equal to price, 0 is returned.
 * @param {boolean} [double=false] - A flag indicating whether to round the discount percentage to the nearest integer (false) or to 2 decimal places (true).
 * @returns {number} The discount percentage of the product, rounded to the nearest integer or 2 decimal places based on the value of the double parameter.
 */
export const discountPercentage = (
  price: number,
  oldPrice: number,
  double = false
): number => {
  const newPrice = +price
  const newOldPrice = +oldPrice

  // Return 0 if "newOldPrice" is not provided or is less than or equal to price
  if (!newOldPrice || newOldPrice <= newPrice) return 0

  // Calculate the discount percentage
  let discountPercentage = ((newOldPrice - newPrice) / newOldPrice) * 100

  if (discountPercentage > 0 && discountPercentage < 1) discountPercentage = 1

  // Round the discount percentage to the nearest integer or 2 decimal places based on the value of double
  return double
    ? +discountPercentage.toFixed(2)
    : Math.round(discountPercentage)
}

/**
 * Returns a string representing the formatted price per unit.
 *
 * @param {string} price - The price of the product or service.
 * @param {string} unit - The unit of measurement for the product or service.
 * @returns {string} A string representing the formatted price per unit.
 */
export const formattedPricePerUnit = (
  price: string = "",
  unit: string = ""
): string => {
  return `(${price}/${unit})`
}

export const formatNameWithoutBackSlash = (text: string) => {
  return text.replace(/\\/g, "")
}

export const pricePerUnit = (price: number, quantity: number) => {
  return quantity ? price / quantity : 0
}
