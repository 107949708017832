import { Coupon, GiftCard } from "@commercelayer/sdk"
import { DiscountCodeProps } from "components/Card/DiscountCode.props"
import { DataUser } from "stores/user"
import {fetchCouponsOnce} from "~/utils/coupons";

export const getDiscountCodes = async (
  isLogged: string,
  user: DataUser | null = null,
  appliedDiscountCode: string | null | undefined
): Promise<DiscountCodeProps[]> => {
  if (!isLogged) {
    return [
      {
        discount: 5,
        discountCode: "WELCOME5",
        isBig: true,
        status: "default",
        symbol: "%",
        ctaFunction: "signup",
        minOrder: 59
      }
    ]
  } else {
    const getCoupons = await fetchCouponsOnce(isLogged)
    const dataCoupons = getCoupons?.coupons.filter(
      (i: Coupon) => i && !!i.promotion_rule?.promotion?.active
    )
    const dataGiftCards = getCoupons?.giftCards.filter(
      (item: GiftCard) =>
        item.recipient_email === user?.email && item.status !== "redeemed"
    )

    const isNotRegistered =
      isLogged && !user?.isSubscribed && !user?.hasPreviousCoupons
        ? {
            discount: 5,
            discountCode: "SUBSCRIBE5",
            isBig: true,
            status: "default",
            symbol: "%",
            ctaFunction: "subscribe",
            minOrder: 59
          }
        : null

    const coupons =
      dataCoupons.length > 0
        ? dataCoupons.map((coupon: Coupon) => {
            const promotion = coupon?.promotion_rule?.promotion
            return {
              discount:
                promotion.type === "percentage_discount_promotions"
                  ? parseInt(promotion.percentage)
                  : promotion?.formatted_fixed_amount,
              discountCode: coupon.code,
              isBig: true,
              status:
                (coupon.usage_limit &&
                  coupon.usage_count === coupon.usage_limit) ||
                (coupon.customer_single_use &&
                  coupon.usage_count &&
                  coupon.usage_count > 0)
                  ? "used"
                  : coupon.code === appliedDiscountCode
                  ? "applied"
                  : "ready",
              symbol:
                promotion.type === "percentage_discount_promotions" ? "%" : "€",
              ctaFunction: "applyCode"
            }
          })
        : null

    const giftCards =
      dataGiftCards.length > 0
        ? dataGiftCards.map((giftCard: GiftCard) => {
            return giftCard.balance_float !== 0
              ? {
                  discount: Math.floor(giftCard.balance_float),
                  discountCode: giftCard.code,
                  isBig: true,
                  status: giftCard.balance_float !== 0 ? "ready" : "used",
                  symbol: "€",
                  isGiftCard: true,
                  ctaFunction: "applyCode"
                }
              : null
          })
        : null

    return _flattenDeep([coupons, isNotRegistered, giftCards]).filter(
      (items) => items !== null
    )
  }
}
