<script lang="ts">
import { Remarkable } from "remarkable"

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true
    },
    inline: { type: Boolean, required: false, default: true },
    tag: { type: String, required: false, default: "p" }
  },

  setup(props) {
    const remarkable = new Remarkable({ html: true })
    const remarkableEnhanced = (md: string) => {
      if (md.includes("</table>")) {
        md = insertNewlineAfterTable(md)
      }

      const content = md.split(/\n/).filter((element) => element !== "")

      const htmls = content.map((l) => {
        const stringWithTag = [" ", "\t", "#", "-", "*"].some((ch) =>
          l.startsWith(ch)
        )
        const stringWithStrong = ["**", "__"].some((ch) => l.startsWith(ch))
        if (stringWithTag) {
          if (stringWithStrong) {
            return isPNestable.value
              ? `${remarkable.render(l)}`
              : `${removeWrapperParagraph(remarkable.render(l))}`
          }
          return removeWrapperParagraph(remarkable.render(l))
        } else {
          return isPNestable.value
            ? `${remarkable.render(l)}`
            : `${removeWrapperParagraph(remarkable.render(l))}`
        }
      })

      return htmls.join(isPNestable.value ? "" : "</br>")
    }

    const removeWrapperParagraph = (str: string) => {
      return str.trim().replace(/^<p[^>]*>([\S\s]*)<\/p>$/g, "$1")
    }

    /**
     * Inserts a newline character after every </table> HTML tag found inside the string passed as param. If there is already a \n character after </table>, returns that section of the string as it was originally. Can be converted for use with other edge cases.
     *
     * @param str the string containing HTML and markdown you want to parse
     * @return string
     */
    const insertNewlineAfterTable = (str: string) => {
      // splits the string for every closing table tag, and concats it to the result
      const tableTags = str.split(/(?<=<\/table>)/g)

      if (tableTags.length <= 1) return str

      return tableTags
        .map((tag: string, i: number) => {
          const trimmedTag = tag.trim()
          // filters out the first element in the array (will never be preceded by </table>)
          if (i === 0) return trimmedTag

          // concats \n to all the other strings where it's not already there
          return !trimmedTag.startsWith("\n") ? `\n${trimmedTag}` : trimmedTag
        })
        .join("")
    }

    //checks if the rendered tag is one of the HTML tags in the array, in order to avoid nesting a <p> where it shouldn't be, e.g. another <p>.
    const isPNestable = computed<boolean>(() => {
      return !["p", "h1", "h2", "h3", "h4", "h5", "h6", "span"].includes(
        renderedTag.value
      )
    })

    const markdownContent = ref(
      removeWrapperParagraph(remarkable.render(props.content))
    )

    onMounted(async () => {
      markdownContent.value = await remarkableEnhanced(props.content)
    })

    onUpdated(() => {
      markdownContent.value = remarkableEnhanced(props.content)
    })
    const renderedTag = computed(() => (props.inline ? props.tag : "div"))

    return () =>
      h(renderedTag.value, {
        class: props.inline
          ? "utils-markdown"
          : "utils-markdown whitespace-pre-line",
        innerHTML: markdownContent.value
      })
  }
})
</script>
<style lang="scss">

.utils-markdown {
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

</style>
