// https://github.com/gtm-support/vue-gtm#configuration
// import { createGtm } from "@gtm-support/vue-gtm"

export default defineNuxtPlugin((nuxtApp) => {
  //   const runtimeConfig = useRuntimeConfig().public
  //   nuxtApp.vueApp.use(
  //     createGtm({
  //       id: runtimeConfig.GTM_CONTAINER_ID,
  //       defer: false,
  //       enabled: true,
  //       debug: true,
  //       loadScript: true,
  //       vueRouter: useRouter(),
  //       trackOnNextTick: false
  //     })
  //   )
})
