import type {
  SkuList,
  SkuListItem,
  QueryParamsRetrieve,
  SkuListCreate,
  SkuListItemCreate
} from "@commercelayer/sdk"
import { NotificationAlertProps } from "~/components/NotificationAlert.props"

export const defaultQuerySkuList: QueryParamsRetrieve = {
  include: ["sku_list_items", "sku_list_items.sku", "sku_list_items.sku.prices"]
}

export default (
  key: string,
  id: string | null = null,
  { query = defaultQuerySkuList } = {}
) => {
  const { $cl } = useNuxtApp()
  const { addNotification } = useNotification()
  const { t } = useI18n()

  const skuListId = useState<string | null>(`skuListId-${key}`, () => id)

  const initSkuList = (id: string | null) => {
    skuListId.value = id
  }

  const skuList = useState<SkuList | null>(`skuList-${key}`, () => null)

  watch(skuListId, (skuListId) => {
    if (!skuListId) {
      skuList.value = null
    }
  })

  const skuListItems = computed(() => skuList.value?.sku_list_items ?? [])

  // LOADING UTILS
  const loading = useState<boolean>(`skuList-key-loading`, () => true)

  // CREATE, UPDATE, FETCH, INIT METHODS
  const createSkuList = async (attributes: SkuListCreate) => {
    if (skuList.value) {
      return
    }

    loading.value = true

    skuList.value = await $cl.sku_lists.create(attributes, query)

    skuListId.value = skuList.value.id

    loading.value = false
  }

  const fetchSkuList = async () => {
    if (!skuListId.value) {
      return
    }

    loading.value = true

    try {
      const data = await $cl.sku_lists.retrieve(skuListId.value, query)

      skuList.value = data
    } catch (err: any) {
      console.error("Error", err.response || err)
    }

    loading.value = false
  }

  const addSkuItem = async (
    attributes: Omit<SkuListItemCreate, "sku_list">,
    refresh = true
  ) => {
    if (!skuList.value) {
      return
    }

    const skuItem = await $cl.sku_list_items.create({
      ...attributes,
      sku_list: { id: skuList.value!.id, type: "sku_lists" }
    })
    await new Promise((resolve) => setTimeout(resolve, 200))
    if (refresh) await fetchSkuList()

    return skuListItems.value.find((item) => item.id === skuItem.id)
  }

  const removeSkuItem = async (id: string, refresh = true) => {
    const item = skuListItems.value.find((item) => item.id === id)
    if (!item) return
    await $cl.sku_list_items.delete(id)

    await new Promise((resolve) => setTimeout(resolve, 200))
    if (refresh) await fetchSkuList()

    unsavedForLaterNotification()

    return item
  }

  const unsavedForLaterNotification = () => {
    const { isOpen } = useUIState("focus-search")
    const notification: NotificationAlertProps = {
      id: Math.random(),
      type: "success",
      notificationAlertText: t("productTile.overall.unsavedForLater"),
      isWithCloseIcon: true,
      small: isOpen.value
    }

    addNotification(notification)
  }

  const removeSkuItemBySku = async (skuCode: string, refresh = true) => {
    const id = skuListItems.value.find((item) => item.sku_code === skuCode)?.id
    if (id === undefined) return
    return removeSkuItem(id, refresh)
  }

  const isInSkuList = (sku: string) => {
    return skuListItems.value.some((item) => item.sku_code === sku)
  }

  return {
    skuListId,
    skuList,
    skuListItems,
    loading,
    initSkuList,
    createSkuList,
    fetchSkuList,
    addSkuItem,
    removeSkuItem,
    removeSkuItemBySku,
    isInSkuList
  }
}
