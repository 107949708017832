import { CommerceLayer } from "@commercelayer/sdk"
import axios from "axios"
import jwtDecode from "jwt-decode"
import { createStorage } from "unstorage"
const storage = createStorage()

export type Token = {
  access_token: string
  token_type: "bearer"
  expires_in: number
  scope: string
  created_at: number
}

export const getHost = (organization: string) =>
  `https://${organization}.commercelayer.io`

export const getAuthorizationHost = () => `https://auth.commercelayer.io`

export const getScope = (marketId: string) => `market:id:${marketId}`

export const fetchGuestToken = async (
  clientId: string,
  marketId: string,
  organization: string
) => {
  console.log("fetching token from commerce layer")
  const data = await $fetch<Token>(`${getAuthorizationHost()}/oauth/token`, {
    method: "POST",
    body: {
      grant_type: "client_credentials",
      client_id: clientId,
      scope: getScope(marketId)
    }
  })

  return data.access_token
}

export const getIntegrationToken = async (
  clientId: string,
  clientSecret: string,
  marketId: string,
  organization: string
) => {
  const key = "commerceLayer:integration:token"
  const cachedToken = await storage.getItem<string>(key)
  if (cachedToken && isValid(cachedToken)) return cachedToken

  /*
    const { value: redisToken, addToCache } = await useDataCache<string>(
      key,
      event
    )
    if (redisToken && isValid(redisToken)) {
      await storage.setItem(key, redisToken)
      return redisToken
    }
    */

  const token = await fetchIntegrationToken(
    clientId,
    clientSecret,
    marketId,
    organization
  )

  await storage.setItem(key, token)
  //await addToCache(token)

  return token
}

const fetchIntegrationToken = async (
  clientId: string,
  clientSecret: string,
  marketId: string,
  organization: string
) => {
  const data = await $fetch<Token>(`${getAuthorizationHost()}/oauth/token`, {
    method: "POST",
    body: {
      grant_type: "client_credentials",
      client_id: clientId,
      client_secret: clientSecret,
      scope: getScope(marketId)
    }
  })

  return data.access_token
}

type Auth = {
  getToken: () => string
  refreshToken?: () => Promise<string>
  onRefreshError?: (err: unknown) => void
}

// Function to create a CommerceLayer client
export const createCommerceLayerClient = (organization: string, auth: Auth) => {
  const cl = CommerceLayer({
    organization,
    accessToken: auth.getToken()
  })

  // Intercept each request to add the current access token to the headers
  cl.addRequestInterceptor((config) => ({
    ...config,
    headers: {
      ...config.headers,
      authorization: `Bearer ${auth.getToken()}`
    }
  }))

  // Intercept each response to refresh the access token if it's expired
  // @ts-ignore
  cl.addResponseInterceptor(undefined, async (error) => {
    if (!auth.refreshToken) {
      return Promise.reject(error)
    }

    const isRefreshTokenError = !!(
      error.response?.status === 401 &&
      // @ts-ignore
      error.response?.data?.errors?.[0]?.code === "INVALID_TOKEN"
    )

    if (!isRefreshTokenError) {
      return Promise.reject(error)
    }

    const errorConfig = error.config

    if (!errorConfig) {
      return Promise.reject(error)
    }

    // @ts-ignore
    const retries = errorConfig?.__retryAttempts ?? 0

    if (retries >= 3) {
      return Promise.reject(error)
    }

    try {
      const newToken = await auth.refreshToken()
      // @ts-ignore
      errorConfig.__retryAttempts = (errorConfig.__retryAttempts ?? 0) + 1
      errorConfig.headers = JSON.parse(JSON.stringify(errorConfig.headers))
      errorConfig.headers.authorization = `Bearer ${newToken}`
      // @ts-ignore
      return axios.request(errorConfig)
    } catch (err) {
      if (auth.onRefreshError) auth.onRefreshError(err)
      return Promise.reject(error)
    }
  })

  return cl
}

export type AccessTokenPayload = {
  organization: Organization
  application: Application
  test: boolean
  exp: number
  market: Market
  rand: number
}

export type Application = {
  id: string
  kind: string
  public: boolean
}

export type Market = {
  id: string[]
  price_list_id: string
  stock_location_ids: string[]
  geocoder_id: null
  allows_external_prices: boolean
}

export type Organization = {
  id: string
  slug: string
  enterprise: boolean
}

export const isValid = (accessToken: string) => {
  try {
    const payload = jwtDecode<AccessTokenPayload>(accessToken)
    const { exp } = payload
    const expMs = exp * 1000
    const now = Date.now()
    const isValid = now < expMs
    return isValid
  } catch (err) {
    return false
  }
}
