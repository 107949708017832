import { defineNuxtRouteMiddleware, navigateTo } from "nuxt/app";

export type RedirectSpecification = {
  uri: string,
  redirectCode: 301|302|307|308,
  external: boolean
}

type TypedRedirects = {
  punctualRedirects: PunctualRedirects,
  regexRedirects: RegexRedirects,
  punctualDoNotRedirect: PunctualDoNotRedirects,
  regexDoNotRedirect: string[]
}
type PunctualDoNotRedirects = {
  [key: string]: boolean;
};
type RegexRedirectContainer = {
  regex: string,
  compiled_regex?: RegExp,
  children: RegexRedirects,
  redirects: RegexRedirect[]
 }
type RegexRedirect = {
    code: 301|302|307|308,
    from: string,
    from_regex?: RegExp,
    to: string
};
type RegexRedirects = {
  [key: string]: RegexRedirectContainer
};
type PunctualRedirect = {
  "query": string[],
  "code": 301|302|307|308,
  "to": string
}
type PunctualRedirects = {
  [key: string]: PunctualRedirect[]
};    

let typedRedirects : TypedRedirects | undefined;

export const evaluateRedirect : (fullPathTo: string) => Promise<RedirectSpecification | null | undefined> = async (fullPathTo: string) => {
  if (fullPathTo.includes("robots.txt")) {
    //console.timeEnd("redirect_end-to-end");
    return null
  }
  
  const getTypedRedirects = (redirects: any) => redirects as TypedRedirects;
  const _compileRegex = (regexRedirects: RegexRedirectContainer) => {
    if(regexRedirects.regex){
      regexRedirects.compiled_regex = new RegExp(regexRedirects.regex);
    }
    for(let regexRedirect of regexRedirects?.redirects ?? []){
      regexRedirect.from_regex = new RegExp(regexRedirect.from);
    }
    if(regexRedirects?.children){
      for(let childKey of Object.keys(regexRedirects.children)){
        let child = regexRedirects.children[childKey];
        if(child){
          regexRedirects.children[childKey] = _compileRegex(child);
        }
      }
    }
    return regexRedirects;
  }
  const compileRedirects = (redirects: TypedRedirects) => {
    try{
      for(let childKey of Object.keys(redirects.regexRedirects)){
        let child = redirects.regexRedirects[childKey];
        if(child){
          redirects.regexRedirects[childKey] = _compileRegex(child);
        }
      }
      return redirects;
    }catch(err){
      console.error(err);
    }
  }
 
  // if redirection is only server side, skip redirection entirely, else load redirection rules
  let redirects: any;
  if (!false && !process.server) {
    //console.timeEnd("redirect_end-to-end");
    return
  } else if(!typedRedirects) {  
    //console.time("redirect_get-redirects");
    const { redirects: r } = await import("./redirects")
    //console.timeEnd("redirect_get-redirects");
    redirects = r
    //console.time("redirect_get-typed-redirects");
    // divide redirection rules in punctual redirects and regex redirection rules
    typedRedirects = getTypedRedirects(redirects);
    //console.timeEnd("redirect_get-typed-redirects")
    //console.time("redirect_compile-redirects");
    typedRedirects = compileRedirects(typedRedirects);
    //console.timeEnd("redirect_compile-redirects")

  }
  
  const hasTrailingSlash = (path: string) => path.endsWith("/")
  const removeTrailingSlash = (path: string) => hasTrailingSlash(path) ? path.slice(0, -1) : path
  const addTrailingSlash = (path: string) => {
    if (false) {
      if (!hasTrailingSlash(path)) {
        return path + "/"
      }
    }
    return path
  }
  const getQueriesFromPath = (path: string) => {
    return path.split("?")?.[1]?.split("&") ?? []
  }

  const joinQueriesAndPath = (path: string, queries: string[]) => {
    if (!queries.length) return path
    return path + "?" + queries.join('&')
  }

  const getUrl = (path: string) => {
    return path.split("?")[0]
  }

  const getQuery = (path: string) => {
    return path.split("?")?.[1] ?? ''
  }

  const findRegexRedirect : (path: string, regexRedirects: RegexRedirects) => RegexRedirect | undefined =  (path: string, regexRedirects: RegexRedirects) => {
    let matchingRedirect : RegexRedirect | undefined;
    try{
      for(const key of Object.keys(regexRedirects).filter(k => k != 'root')){
        const child = regexRedirects[key];
        if(child?.compiled_regex?.test(path)) {
          if(child?.children && Object.keys(child.children).length > 0){
            matchingRedirect = findRegexRedirect(path, child.children)
          }
          if(!matchingRedirect && child?.redirects?.length > 0){
            for(let index = child.redirects.length - 1; index >= 0; index--){
              const regexRedirect = child.redirects[index];
              if (regexRedirect?.from_regex?.test(path)) {
                matchingRedirect = regexRedirect;
                break;
              }
            }
          }
          if(matchingRedirect){
            break;
          }
        }
      }
      if(!matchingRedirect && (regexRedirects["root"]?.redirects?.length ?? 0) > 0){
        for(let index = regexRedirects["root"].redirects.length - 1; index >= 0; index--){
          const regexRedirect = regexRedirects["root"].redirects[index];
          if (regexRedirect?.from_regex?.test(path)) {
            matchingRedirect = regexRedirect;
            break;
          }
        }
      }
    }catch(err){
      //console.log(err);
    }
    return matchingRedirect;
  }

  // redirection only happens server side
  if (process.server || false) {
    // removing = and trailingSlash, ignore query
    let path = removeTrailingSlash(fullPathTo.endsWith("=")
      ? fullPathTo.slice(0, -1)
      : fullPathTo);

    const query = getQuery(path)
    const urlPath = getUrl(path)
    const queryArray = query ? query.split("&") : [];

    if (fullPathTo !== "/") {  
      //console.time("redirect_check-blacklist");
      // Checking if path is on blacklist (punctual or regex)  
      if(Object.hasOwn(typedRedirects.punctualDoNotRedirect, urlPath)){
        return null;
      }else if(typedRedirects.regexDoNotRedirect){
        typedRedirects.regexDoNotRedirect.forEach((r) => {
          const regex = new RegExp(r);
          if (regex.test(path)) {
            return null;
          }
        });
      }
      //console.timeEnd("redirect_check-blacklist");

      //console.time("redirect_punctual-redirect-search");
      let punctualRedirection : PunctualRedirect | undefined;
      let tentativePunctualRedirections = typedRedirects.punctualRedirects[urlPath] ?? [];
      tentativePunctualRedirections = tentativePunctualRedirections.sort((a,b) => b.query.length - a.query.length);
      for(const tentativePunctualRedirection of tentativePunctualRedirections){
        if(tentativePunctualRedirection.query.every(q => queryArray.includes(q))){
          punctualRedirection = tentativePunctualRedirection;
          break;
        }
      }
      //console.timeEnd("redirect_punctual-redirect-search");

      if (typeof punctualRedirection !== "undefined") {
        // join request and redirection queries
        const queryParams = [...new Set([
          ...getQueriesFromPath(fullPathTo),
          ...punctualRedirection.query
        ])]
        const uri = joinQueriesAndPath(addTrailingSlash(punctualRedirection.to), queryParams) 
        //console.timeEnd("redirect_end-to-end");
        return {
          uri,
          redirectCode: punctualRedirection.code,
          external: true,
        };
      }
      // punctual redirects had the priority, now we look for any regex redirections
      
      //console.time("redirect_regex-redirect-search");
      let regexRedirection = findRegexRedirect(path, typedRedirects.regexRedirects);
      //console.timeEnd("redirect_regex-redirect-search");

      if(regexRedirection){
        const regex = new RegExp(regexRedirection.from);
        const toPath = addTrailingSlash(urlPath.replace(regex, regexRedirection.to));
        
        const queryParams = [...new Set([
          ...getQueriesFromPath(fullPathTo),
          ...getQueriesFromPath(toPath)
        ])]

        const uri = joinQueriesAndPath(addTrailingSlash(toPath), queryParams)
        //console.timeEnd("redirect_end-to-end");
        return {
          uri, 
          redirectCode: regexRedirection.code,
          external: true,
        };
      }
     
      // trailing-slash
      if (false) {
        const originalSplittedPath = fullPathTo.split("?")
        const originalPath = originalSplittedPath[0]

        if (!hasTrailingSlash(originalPath)) {
          originalSplittedPath[0] = addTrailingSlash(originalPath)
          //console.timeEnd("redirect_end-to-end");
          return {
            uri: originalSplittedPath.join("?"),
            redirectCode: 301,
            external: true
          }
        }
      }
    }
  }
}

export default defineNuxtRouteMiddleware(async (to, _) => {
  const redirectSpecification = await evaluateRedirect(to.fullPath);
  if(!!redirectSpecification){
    return navigateTo(redirectSpecification.uri,{
      redirectCode: redirectSpecification.redirectCode,
      external: redirectSpecification.external
    })
  }
});

