<script setup lang="ts">
import type { GA4Entities } from "nuxt-ga4"
import type { VerticalProps } from "./ProductTile/Overall/Vertical.props"
const { mapProduct } = useGA4SiteEvents()

const props =
  defineProps<{
    id: string
    item?: VerticalProps[] //TODO: rename this to "items" instead
    disable?: boolean
    listName?: string
    key?: string
  }>()

const statefulEntityList:
  | [Exclude<keyof GA4Entities, "cartList" & "gaItem">]
  | undefined = inject("statefulEntityList")

const payload = computed<GA4Entities["gaItem"][]>(
  () =>
    props.item?.map((item, i) =>
      mapProduct(
        item,
        i,
        props.listName ?? statefulEntityList?.value?.list_name ?? ""
      )
    ) ?? []
)

const statefulEntityItem = useGAEntity(
  `ga_item_${props.id}` as keyof GA4Entities
)
statefulEntityItem.value = payload?.value ?? {}

// Whenever the "item" prop changes, it will re-evalute the "statefulEntityItem" so you would just have to "catch" it with a "watch"
watch(
  () => props?.item,
  (newValue, oldValue) => {
    if (!isEqual(newValue, oldValue) && !props.disable) {
      statefulEntityItem.value = payload?.value ?? {}
    }
  }
)

if (!props?.disable) {
  provide("statefulEntityItem", statefulEntityItem)
  provide("id", props.id)
}
</script>

<template>
  <slot />
</template>
