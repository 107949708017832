import { defineNuxtPlugin } from "#app"
import * as Sentry from "@sentry/vue"

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig().public.sentry ?? ""
  const router = useRouter()

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config?.SENTRY_DNS ?? "",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],

    enabled: config?.SENTRY_ENABLED ?? true,
    environment: config.SENTRY_ENVIROMENT ?? "development",
    sampleRate: config?.SENTRY_SAMPLE_RATE ?? 0.2,
    profilesSampleRate: config.SENTRY_PROFILES_SAMPLE_RATE ?? 0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: config.SENTRY_TRACES_SAMPLE_RATE ?? 0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "https://deploy-preview-107--topfarmacia-main.netlify.app",
      "https://www.topfarmacia.it"
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: config.SENTRY_REPLAYS_SESSIONS_SAMPLE_RATE ?? 0,
    replaysOnErrorSampleRate: config.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? 0
  })
})
