import { pushDataLayer } from "~/utils/analytics/analytics"

export enum clickCtaEvent {
  clickCta = "click_cta"
}

export enum clickCtaCategory {
  click = "click"
}

export enum clickCtaAction {
  menu = "menu",
  cart = "carrello",
  header = "header",
  product = "prodotto",
  productCategory = "categoria_prodotto",
  banner = "banner"
}

export interface ClickCtaDataLayerPayload {
  event: clickCtaEvent
  category: string
  action: string
  label: string
}

export function getClickCtaPayload(
  action: string,
  label: string
): ClickCtaDataLayerPayload {
  return {
    event: clickCtaEvent.clickCta,
    category: clickCtaCategory.click,
    action,
    label
  }
}

export function pushMenuClickCtaEvent(label: string) {
  const clickCtaPayload = getClickCtaPayload(clickCtaAction.menu, label)
  pushDataLayer(clickCtaPayload)
}

export function pushHeaderClickCtaEvent(label: string) {
  const clickCtaPayload = getClickCtaPayload(clickCtaAction.header, label)
  pushDataLayer(clickCtaPayload)
}

export function pushProductClickCtaEvent(label: string) {
  const clickCtaPayload = getClickCtaPayload(clickCtaAction.product, label)
  pushDataLayer(clickCtaPayload)
}

export function pushProductCategoryClickCtaEvent(label: string) {
  const clickCtaPayload = getClickCtaPayload(
    clickCtaAction.productCategory,
    label
  )
  pushDataLayer(clickCtaPayload)
}

export function pushCartClickCtaEvent(label: string) {
  const clickCtaPayload = getClickCtaPayload(clickCtaAction.cart, label)
  pushDataLayer(clickCtaPayload)
}

export function pushBannerClickCtaEvent(label: string) {
  const clickCtaPayload = getClickCtaPayload(clickCtaAction.banner, label)
  pushDataLayer(clickCtaPayload)
}
