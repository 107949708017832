import { NotificationAlertProps } from "~/components/NotificationAlert.props"

export const useNotification = () => {
  const notifications = useState<NotificationAlertProps[]>(
    "notifications",
    () => []
  )

  const addNotification = (notification: NotificationAlertProps) => {
    notifications.value.push(notification)
  }

  const removeNotification = (id: string | number) => {
    notifications.value = notifications.value.filter((n) => n.id !== id)
  }

  const resetNotifications = () => {
    notifications.value = []
  }

  return {
    notifications,
    addNotification,
    removeNotification,
    resetNotifications
  }
}
