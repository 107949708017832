export const analyticsCategories = {
  account: "account",
  brand: "brand",
  cart: "cart",
  category: "category",
  checkout: "checkout",
  contact: "contact",
  encyclopedia: "encyclopedia",
  home: "homepage",
  magazine: "magazine",
  other: "other",
  product: "product",
  promo: "promo",
  search: "search"
}

export enum analyticsItemListTypes {
  home = "homepage",
  category = "category",
  relatedProducts = "related products",
  search = "search",
  crossSell = "cross-sell"
}
