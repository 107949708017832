// middleware/saveReturnUrl.ts
import { loginSignupPath, appPaths } from "~/utils/paths/paths";
import {returnUrlStorageKey} from "~/utils/loginSignup/loginSignup";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const isLogged = useIsLogged()
  if (isLogged?.value) {
    return true
  }

  const returnUrl = from.fullPath;
  const accessPath = new RegExp(`^${loginSignupPath}`);
  const passwordRecoveryPath = new RegExp(`^${appPaths.passwordForget}`);
  const socialLoginPath = new RegExp(`^${appPaths.socialLogin}`);

  if (process.client && !accessPath.test(returnUrl) && !passwordRecoveryPath.test(returnUrl) && !socialLoginPath.test(returnUrl)) {
    localStorage.setItem(returnUrlStorageKey, returnUrl)
  }
})
