export const newsletterManager = {
  isFetching: false,
  data: null as any,
  fetchPromise: null as Promise<any> | null,
};

export async function fetchNewsletterOnce(authToken: string): Promise<any> {
  // Return fetched data if exists.
  if (newsletterManager.data) {
    return newsletterManager.data;
  }

  // Return a promise if we are still fetching user newsletter.
  if (newsletterManager.isFetching && newsletterManager.fetchPromise) {
    return newsletterManager.fetchPromise;
  }

  // Let's do the real newsletter fetching at this point.
  newsletterManager.isFetching = true;
  newsletterManager.fetchPromise = $fetch(`/api/account/checkNewsletter/${authToken}`)
    .then((response) => {
      newsletterManager.data = {
        isSubscribed: response?.subscription_status,
        hasPreviousCoupons: response?.has_previous_coupons
      }
      newsletterManager.isFetching = false;
      newsletterManager.fetchPromise = null;
      return newsletterManager.data;
    })
    .catch((error) => {
      newsletterManager.isFetching = false;
      newsletterManager.fetchPromise = null;
      throw error;
    });

  return newsletterManager.fetchPromise;
}
