export const useFocalPoint = (
  x: number,
  y: number,
  width: number,
  height: number
) => {
  const focalPoint = computed(() => {
    const focalX = (x * 100) / width
    const focalY = (y * 100) / height
    return { "object-position": `${focalX}% ${focalY}%` }
  })

  return {
    focalPoint
  }
}
