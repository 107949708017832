<script setup lang="ts">
import { TransitionRoot, TransitionChild, Dialog } from "@headlessui/vue"

interface ModalClasses {
  modalAlphaLayer?: string
  modalBox?: string
  modalBlock?: string
  modalHeader?: string
  modalClose?: string
  modalBody?: string
  modalFooter?: string
}

interface ModalTransitionClasses {
  enter: string
  enterFrom: string
  enterTo: string
  leave: string
  leaveFrom: string
  leaveTo: string
}

type ModalProps = {
  id: string
  opened?: boolean
  customClasses?: ModalClasses
  customTransitions?: ModalTransitionClasses
}

const props = withDefaults(defineProps<ModalProps>(), {
  id: "",
  opened: false
})

const defaultClasses = ref<ModalClasses>({
  modalAlphaLayer:
    "fixed inset-0 z-10 px-s pb-l md:p-xl bg-black bg-opacity-70 overflow-y-auto",
  modalBox: "flex auto boxed m-auto h-full",
  modalBlock:
    "m-auto flex flex-col p-m lg:p-xl bg-white rounded-2xl shadow-xl overflow-hidden transition-all transform",
  modalHeader: "flex justify-end",
  modalBody: "h-full my-l overflow-auto"
})

const defaultTransitionClasses = ref<ModalTransitionClasses>({
  enter: "duration-300 ease-out",
  enterFrom: "opacity-0 scale-95",
  enterTo: "opacity-100 scale-100",
  leave: "duration-200 ease-in",
  leaveFrom: "opacity-100 scale-100",
  leaveTo: "opacity-0 scale-95"
})

const componentClasses = computed(() => {
  return { ...defaultClasses.value, ...props.customClasses }
})

const customTransitions = computed(() => {
  return { ...defaultTransitionClasses.value, ...props.customTransitions }
})

const { statusModal, initModal, openModal, closeModal } = useModal()

initModal(props.id)

if (props.opened) {
  openModal()
}
</script>

<template>
  <TransitionRoot appear :show="statusModal" as="template">
    <Dialog as="div" @close="closeModal()" class="modal">
      <div class="modal__alpha-layer" :class="componentClasses.modalAlphaLayer">
        <div class="modal__box" :class="componentClasses.modalBox">
          <TransitionChild as="template" v-bind="customTransitions">
            <div class="modal__block" :class="componentClasses.modalBlock">
              <div class="modal__header" :class="componentClasses.modalHeader">
                <div class="modal__close" :class="componentClasses.modalClose">
                  <slot name="closer">
                    <button @click="closeModal()">X</button>
                  </slot>
                </div>
              </div>

              <div class="modal__body" :class="componentClasses.modalBody">
                <slot name="body" />
              </div>

              <div class="modal__footer" :class="componentClasses.modalFooter">
                <slot name="footer" />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
