<script setup lang="ts">
import { QuantityPickerProps } from "./QuantityPicker.props"
const props = withDefaults(defineProps<QuantityPickerProps>(), {
  max: 10
})

const emit =
  defineEmits<{
    (e: "update:modelValue", value: number): void
  }>()

function handleDecrease() {
  if (props.quantity === 1) return
  else {
    emit("update:modelValue", props.quantity - 1)
  }
}
function handleIncrease() {
  if (props.quantity === props.max) return
  else {
    emit("update:modelValue", props.quantity + 1)
  }
}
</script>

<template>
  <div
    class="
      modelValue-picker-wrapper
      flex
      items-center
      justify-between
      rounded
      border border-black-10
    "
  >
    <button
      @click="handleDecrease"
      class="
        flex
        h-10
        w-10
        items-center
        justify-center
        border-r border-black-10
      "
      :class="[
        quantity === 1
          ? 'cursor-not-allowed text-slate-40'
          : 'cursor-pointer text-black-main'
      ]"
    >
      <IconsMinus class="h-6 w-6" />
    </button>
    <p class="pig black-main flex items-center justify-center">
      {{ $t("utils.quantityPicker.quantity") }}
      <span v-if="quantity" class="ml-1">{{ quantity }}</span>
    </p>

    <button
      @click="handleIncrease"
      class="
        flex
        h-10
        w-10
        items-center
        justify-center
        border-l border-black-10
      "
      :class="[
        quantity === max
          ? 'cursor-not-allowed text-slate-40'
          : 'cursor-pointer text-black-main'
      ]"
    >
      <IconsPlus class="h-6 w-6" />
    </button>
  </div>
</template>
