import { Sku } from "@commercelayer/sdk"

/**
 * @param {string} unitType - The unit type to check
 * @returns {boolean} Returns true if the unit type is ok
 */
// TODO: Use an enum to check if the unit type is something in our cms
const checkUnitType = (unitType = ""): boolean => {
  return Boolean(unitType)
}

/**
 * @param {number} pricePerUnit - the price per unit
 * @param {string} unitType - unit type to check
 * @returns {boolean} Returns true if the Price per unit is displayable
 */
const isDisplayablePricePerUnit = (
  pricePerUnit?: number | string,
  unitType?: string
): boolean => {
  const value = Number(pricePerUnit)

  return !isNaN(value) && value > 0 && checkUnitType(unitType)
}

const calculateMultiStockLocationAvailability = (product: Sku | undefined) => {
  return calculateMultiStockLocationStock(product) > 0
}

const calculateMultiStockLocationStock = (product: Sku | undefined) => {
  return Math.max(
    0,
    product?.stock_items?.reduce(
      (cumulated_stock, stock_item) =>
        cumulated_stock +
        (stock_item.quantity ?? 0) -
        (stock_item.reserved_stock?.quantity ?? 0),
      0
    ) ?? 0
  )
}

const isSellableStatus = (sellabilityStatus: string | undefined) => {
  return (
    !sellabilityStatus ||
    !["not_sellable", "out_of_market"].includes(sellabilityStatus)
  )
}

const isIndexableStatus = (sellabilityStatus: string | undefined) => {
  return !sellabilityStatus || !["out_of_market"].includes(sellabilityStatus)
}

export const productUtils = {
  checkUnitType,
  calculateMultiStockLocationStock,
  calculateMultiStockLocationAvailability,
  isDisplayablePricePerUnit,
  isSellableStatus,
  isIndexableStatus
}
