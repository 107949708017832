export const setAccountDefaultAddress = async (
  idCustomerAddress: string,
  userId: string
): Promise<boolean> => {
  const { $cl } = useNuxtApp()

  try {
    const dataUser = await $cl.customers.retrieve(userId)

    if (dataUser) {
      const newDataUser = {
        id: userId,
        email: dataUser.email,
        metadata: {
          name: dataUser.metadata?.name,
          surname: dataUser.metadata?.surname,
          birthday: dataUser.metadata?.birthday,
          fiscalCode: dataUser.metadata?.fiscalCode,
          phone: dataUser.metadata?.phone,
          defaultAddress: idCustomerAddress ?? ""
        }
      }

      const successUpdate = await $cl.customers.update(newDataUser)

      if (successUpdate) {
        return true
      }
    }
  } catch (error) {
    console.error("Error updating user:", error)
  }

  return false
}
