<script setup lang="ts">
type LinkProps = {
  to?: string
  isDisabled?: boolean
  forceSelf?: boolean
  type?: "button" | "submit" | "reset"
  stop?: boolean
  prevent?: boolean
  target?: boolean
}

const props = withDefaults(defineProps<LinkProps>(), {
  isDisabled: false,
  forceSelf: false,
  prevent: false
})

const emit = defineEmits<{ (e: "click"): void }>()

const isExternalLink = computed(() => {
  return !!(
    typeof props.to === "string" &&
    (props.to.match(/^(http(s)?|ftp):\/\//) ||
      props.to === "#" ||
      props.to.startsWith("tel:"))
  )
})

const isNuxtLink = computed(() => {
  return (
    (props.to && isExternalLink.value) || (props.to && !isExternalLink.value)
  )
})

const linkProps = computed(() => {
  if (isExternalLink.value && props.to) {
    return {
      to: props.to,
      target: props.forceSelf ? "_self" : "_blank"
    }
  } else if (isExternalLink.value && !props.to) {
    return {}
  } else {
    return {
      to: props.to
    }
  }
})

const disableProp = ref(props.isDisabled)
const throttleTime = ref(500)

const disabled = computed({
  // getter
  get() {
    return disableProp.value
  },
  // setter
  set(newValue: boolean) {
    disableProp.value = newValue
  }
})
watch(
  () => props.isDisabled,
  (newValue: boolean) => {
    disabled.value = newValue
  },
  { immediate: true }
)
/**
 *
 * @param wait
 * Basic throttling implementation can emit a click after wait timer
 */
const throttling = (wait: number) => {
  if (disabled.value) {
    return
  }

  disabled.value = true

  setTimeout(() => {
    disabled.value = props.isDisabled
  }, wait)
}

const handleRouteChange = (e: Event) => {
  if (props.stop) {
    e.stopPropagation()
  }
  if (!disabled.value) {
    emit("click")
    if (!props.prevent) {
      const open = linkProps?.value?.target
        ? {
            target: linkProps?.value?.target
          }
        : undefined
      navigateTo(props.to ?? "/", {
        external: isExternalLink.value,
        open
      })
    }
  }
  throttling(throttleTime.value)
}

const emitWithModifiers = (e: Event) => {
  if (!disabled.value) {
    if (props.stop) {
      e.stopImmediatePropagation()
    }
    emit("click")
  }
  throttling(throttleTime.value)
}
</script>

<template>
  <a
    v-if="isNuxtLink"
    v-bind="linkProps"
    :href="isDisabled ? 'javascript:void(0)' : to"
    class="app-link cursor-pointer"
    :disabled="isDisabled"
    @click.prevent="handleRouteChange"
  >
    <slot />
  </a>
  <button
    v-else
    v-bind="linkProps"
    class="app-link cursor-pointer"
    :disabled="isDisabled"
    :type="type"
    @click="emitWithModifiers"
  >
    <slot />
  </button>
</template>
