import { addToCartMethodMapping } from "~/utils/constants"

export async function pushDataLayer(data: Record<string, any>): Promise<void> {
  //console.log("GA - PAYLOAD", data)
  if (window && window.dataLayer) {
    // console.log('GA - SENDING DATA')
    window.dataLayer.push(cleanObject(data))
  }
}

export function cleanObject(obj) {
  const cleanedObj = { ...obj }

  for (const key in cleanedObj) {
    const value = cleanedObj[key]
    if (typeof value === "object" && value !== null) {
      cleanedObj[key] = cleanObject(value)
    }

    if (
      cleanedObj[key] === "null" ||
      cleanedObj[key] === null ||
      cleanedObj[key] === undefined ||
      cleanedObj[key] === ""
    ) {
      delete cleanedObj[key]
    }
  }

  return cleanedObj
}

export function formatProduct(product) {
  if (product.price) {
    product.price = formatPrice(product.price)
  }
  if (product.discount) {
    product.discount = formatPrice(product.discount)
  }
  if (product.item_list_name) {
    product.item_list_name = product.item_list_name.substring(0, 32)
  }
  return cleanObject(product)
}

export function formatPrice(price) {
  return isNumeric(price) ? Number(parseFloat(price).toFixed(2)) : price
}

export function isNumeric(value) {
  if (typeof value === "number") {
    return true
  }
  if (typeof value === "string") {
    // Check if the string is a valid number
    return !isNaN(value) && !isNaN(parseFloat(value))
  }
  return false
}

export function mapPosition(position: string) {
  return addToCartMethodMapping[position]
}

export function getAsPipedString(items: string[]): string {
  return items.join("|")
}
