import { CookieOptions } from "#app"

export const useStatefulCookie = <T>(name: string, options?: CookieOptions) => {
  const cookie = useCookie<T | null>(name, options)
  const state = useState(`cookies:${name}`, () => cookie.value)

  const statefulCookie = computed({
    get() {
      return process.client ? cookie.value : state.value
    },
    set(value) {
      state.value = value
      cookie.value = value
    }
  })

  return statefulCookie
}
