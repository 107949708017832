// chiamata a cl per gli shipping methods
import type {
  Order,
  ShippingMethod,
  DeliveryLeadTime
} from "@commercelayer/sdk"

export const hasFreeShipping = (
  order: Order,
  shippingMethod: ShippingMethod
) => {
  const isFree = shippingMethod.price_amount_cents === 0
  if (isFree) {
    return true
  }

  const canBeFree =
    shippingMethod.free_over_amount_cents !== null &&
    shippingMethod.free_over_amount_cents !== undefined
  if (!canBeFree) {
    return false
  }

  const priceToBeFree =
    shippingMethod.free_over_amount_float! -
    (order.subtotal_amount_float! +
      ((shippingMethod as ShippingMethod & { use_subtotal: boolean })
        .use_subtotal
        ? 0
        : order.discount_amount_float ?? 0))

  const isFree_ = priceToBeFree <= 0

  return isFree_ || priceToBeFree
}

export const getMaxDays = (
  deliveryLeadTimes: DeliveryLeadTime[],
  shippingMethod: ShippingMethod
) => {
  const shippingMethodDeliveryLeadTimes = deliveryLeadTimes.filter(
    (dlt) =>
      dlt?.shipping_method?.id && dlt.shipping_method.id === shippingMethod.id
  )

  return Math.max(
    ...shippingMethodDeliveryLeadTimes.map((dlt) => dlt.max_days ?? 0)
  )
}

type ShippingInfo = {
  maxDays?: number
  freeShipping: boolean | number
  shippingMethod: ShippingMethod
}

export default (order: Ref<Order | null>) => {
  // const { $cl } = useNuxtApp()

  const shippingMethod = useState<ShippingMethod | null>(
    `shipping-method`,
    () => ({
      id: "nODkvFJLWE",
      type: "shipping_methods",
      name: "GLS",
      scheme: "flat",
      currency_code: "EUR",
      external_prices_url: "",
      disabled_at: null,
      price_amount_cents: 490,
      price_amount_float: 4.9,
      formatted_price_amount: "€4,90",
      free_over_amount_cents: 1990,
      free_over_amount_float: 19.9,
      formatted_free_over_amount: "€19,90",
      use_subtotal: false,
      price_amount_for_shipment_cents: 0,
      price_amount_for_shipment_float: 0,
      formatted_price_amount_for_shipment: "€0,00",
      min_weight: null,
      max_weight: null,
      unit_of_weight: "gr",
      created_at: "2023-08-03T14:00:23.964Z",
      updated_at: "2023-08-08T14:53:25.349Z",
      reference: "GLS-ITA",
      reference_origin: "",
      metadata: {}
    })
  )

  const deliveryLeadTimes = useState<DeliveryLeadTime[] | null>(
    `delivery-lead-times`,
    () => [
      {
        id: "vxGZoFjGwB",
        type: "delivery_lead_times",
        min_hours: 48,
        max_hours: 96,
        min_days: 2,
        max_days: 4,
        created_at: "2023-08-03T14:00:48.634Z",
        updated_at: "2023-08-03T14:00:48.634Z",
        reference: "",
        reference_origin: "",
        metadata: {},
        shipping_method: {
          id: "nODkvFJLWE",
          type: "shipping_methods",
          name: "GLS",
          scheme: "flat",
          currency_code: "EUR",
          external_prices_url: "",
          disabled_at: null,
          price_amount_cents: 490,
          price_amount_float: 4.9,
          formatted_price_amount: "€4,90",
          free_over_amount_cents: 1990,
          free_over_amount_float: 19.9,
          formatted_free_over_amount: "€19,90",
          use_subtotal: true,
          price_amount_for_shipment_cents: 0,
          price_amount_for_shipment_float: 0,
          formatted_price_amount_for_shipment: "€0,00",
          min_weight: null,
          max_weight: null,
          unit_of_weight: "gr",
          created_at: "2023-08-03T14:00:23.964Z",
          updated_at: "2023-08-08T14:53:25.349Z",
          reference: "GLS-ITA",
          reference_origin: "",
          metadata: {}
        }
      }
    ]
  )

  // const fetched = useState(`shipping-infos`, () => false)

  // const getShippingMethods = async () => {
  //   fetched.value = true

  //   const [shippingMethodsRes, deliveryLeadTimesRes] = await Promise.all([
  //     $cl.shipping_methods.list(),
  //     $cl.delivery_lead_times.list({
  //       include: ["shipping_method"]
  //     })
  //   ])

  //   shippingMethod.value = shippingMethodsRes[0]
  //   deliveryLeadTimes.value = deliveryLeadTimesRes
  // }

  // if (!fetched.value) {
  //   getShippingMethods()
  // }

  const shippingInfos = computed<ShippingInfo | null>(() => {
    if (!order.value || !shippingMethod.value || !deliveryLeadTimes.value) {
      return null
    } else {
      return {
        maxDays: getMaxDays(deliveryLeadTimes.value, shippingMethod.value),
        freeShipping: hasFreeShipping(order.value, shippingMethod.value),
        shippingMethod: shippingMethod.value
      }
    }
  })

  return {
    shippingInfos
    // getShippingMethods
  }
}
