import type { GA4Entities, GA4Events } from "nuxt-ga4"
import type { Order } from "@commercelayer/sdk"
import { SortingModalItemProps } from "~/components/Filters/Utils/SortingModalItem.props"
import {
  cleanObject,
  formatPrice,
  formatProduct,
  mapPosition
} from "~/utils/analytics/analytics"
import { addToCartMethod } from "~/utils/constants"
import sha256 from "crypto-js/sha256.js"
import { getSearchAction } from "~/utils/analytics/search"
import { DataUser } from "~/stores/user"
import type { VerticalProps } from "~/components/ProductTile/Overall/Vertical.props"

type AddToCartPayload = {
  currentPrice?: string
  position?: string
  method?: string
} | null

type RemoveFromCartPayload = {
  currentPrice?: string
} | null

type SaveLaterPayload = {
  currentPrice?: string
} | null

type RemoveQuantityFromCart = {
  quantity?: number
} | null

type AddQuantityFromCart = {
  quantity?: number
  method?: string
} | null

type SelectContentFilter = SortingModalItemProps

export type MethodsType = {
  customerServiceCall: string
  customerServiceChat: string
  customerServiceFaq: string
  customerServiceMail: string
}

export const useGA4SiteEvents = () => {
  /**
   * @description Send a add_to_cart event to gtm
   *
   * @param {GA4Events["custom_event"]} item
   * @param {AddToCartPayload} payload
   */
  const sendEventAddToCart = (
    ga4Item: GA4Events["custom_event"],
    payload: AddToCartPayload = null
  ) => {
    const { event, sendEvent } = useGAEvent(
      `event_add_to_cart_${ga4Item.item_id ?? ""}_`,
      "custom_event"
    )
    event.value = {
      event: "add_to_cart",
      method: payload?.method ?? mapPosition(payload?.position),
      ecommerce: {
        ...cleanObject({
          value: payload?.currentPrice
            ? formatPrice(payload?.currentPrice)
            : "",
          currency: "EUR"
        }),
        items: [formatProduct(ga4Item)]
      }
    }

    sendEvent({ clearEcommerce: false })
  }

  /**
   * @description Updates the cart list based on the line items from the cart.
   *
   * @return {void} This function does not return a value.
   */
  const updateCartList = () => {
    const cartList = useGAEntity("cartList")
    const { lineItems } = useCart()
    if (!lineItems.value?.length || process.server) {
      return
    }

    cartList.value =
      lineItems.value
        ?.map((item) => {
          const ga4Item = item?.metadata?.ga4Item ?? null
          if (ga4Item === null) {
            return ga4Item
          }

          const discount =
            item?.compare_at_amount_float - item?.unit_amount_float

          ga4Item.price = item?.unit_amount_float
          ga4Item.quantity = item?.quantity
          ga4Item.available = item?.metadata?.inStock ? "si" : "no"
          ga4Item.discount = discount > 0 ? discount : null
          return ga4Item
        })
        ?.filter((i) => !!i) ?? []
  }

  /**
   * @description Send a remove_from_cart event to gtm
   *
   * @param {string} productCode
   * @param {RemoveFromCartPayload} payload
   */
  const sendEventRemoveFromCart = (
    ga4Item: GA4Events["custom_event"],
    payload: RemoveFromCartPayload = null
  ) => {
    const { event, sendEvent } = useGAEvent(
      `track_remove_from_cart_${ga4Item.item_id ?? ""}_`,
      "custom_event"
    )

    event.value = {
      event: "remove_from_cart",
      ecommerce: {
        ...cleanObject({
          value: payload?.currentPrice
            ? formatPrice(payload?.currentPrice)
            : "",
          currency: "EUR"
        }),
        items: [formatProduct(ga4Item)]
      }
    }

    sendEvent({ clearEcommerce: false })
  }

  /**
   * @description Send a save_later event to gtm
   *
   * @param {GA4Events["custom_event"]} ga4Item
   * @param {SaveLaterPayload}payload
   */
  const sendEventSaveLater = (
    ga4Item: GA4Events["custom_event"],
    payload: SaveLaterPayload = null
  ) => {
    const { event, sendEvent } = useGAEvent(
      `track_add_to_wishlist_${ga4Item.item_id ?? ""}`,
      "custom_event"
    )

    event.value = {
      event: "add_to_wishlist",
      ecommerce: {
        value: formatPrice(payload?.currentPrice) ?? "",
        currency: "EUR",
        items: [formatProduct(ga4Item)]
      }
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventSelectContent = (
    filter: SelectContentFilter,
    filterType: "sort" | "filter" | undefined,
    category: string
  ) => {
    const { event, sendEvent } = useGAEvent(
      `track_select_content_sorting_`,
      "custom_event"
    )

    event.value = {
      event: "select_content",
      action: filterType,
      category: category,
      label: filter.label
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventCustomerHelp = (
    contactType: "farmacista" | "servizio clienti",
    requestType: string
  ) => {
    const { event, sendEvent } = useGAEvent(
      `track_customer_help_event`,
      "custom_event"
    )

    event.value = {
      event: "customer_help",
      action: "form",
      category: contactType,
      label: requestType
    }

    sendEvent({ clearEcommerce: false })
  }

  const sendEventPhoneHelp = () => {
    const { event, sendEvent } = useGAEvent(
      `track_customer_help_event`,
      "custom_event"
    )

    event.value = {
      event: "customer_help",
      action: "telefono",
      category: "servizio clienti",
      label: ""
    }

    sendEvent({ clearEcommerce: false })
  }

  /**
   * @description Send add_to_cart event every time update quantity from cart
   * @param {string} productCode
   * @param {AddQuantityFromCart} payload
   */
  const addQuantityFromCart = (
    productCode: string,
    payload: AddQuantityFromCart
  ) => {
    const cartList = useGAEntity("cartList")

    const quantity = payload?.quantity ?? 1
    const ga4Item = cartList.value
      ?.flat()
      .find((item) => item?.item_id === productCode)
    sendEventAddToCart(
      { ...ga4Item, quantity },
      {
        currentPrice: ga4Item?.price ? ga4Item.price * quantity : "",
        method: payload?.method ?? addToCartMethod.cart
      }
    )
  }

  /**
   * @description Send remove_to_cart event every time update quantity from cart
   * @param {string} productCode
   * @param {RemoveQuantityFromCart} payload
   */
  const removeQuantityFromCart = (
    productCode: string,
    payload: RemoveQuantityFromCart
  ) => {
    const cartList = useGAEntity("cartList")

    const quantity = payload?.quantity ?? 1
    const ga4Item = cartList.value
      ?.flat()
      .find((item) => item?.item_id === productCode)

    sendEventRemoveFromCart(
      { ...ga4Item, quantity },
      {
        currentPrice: ga4Item?.price ? ga4Item.price * quantity : ""
      }
    )
  }
  /**
   * @description Send a save_later event from cart
   *
   * @param {string} productCode
   */
  const saveLaterFromCart = (productCode: string) => {
    const cartList = useGAEntity("cartList")

    const ga4Item = cartList.value?.find(
      (item) => item?.item_id === productCode
    )

    sendEventSaveLater(
      { ...ga4Item, quantity: 1 },
      {
        currentPrice: ga4Item?.price ?? ""
      }
    )
  }

  const sortFilter = (filter: SelectContentFilter) => {
    const pageList = useGAEntity("pageList")
    if (pageList.value) {
      pageList.value.sort = filter.label ?? ""
    }
    sendEventSelectContent(filter, "sort", "ordina per")
  }

  const customerHelp = (requestType: string) => {
    const contactType =
      requestType === "consulenza farmaceutica"
        ? "farmacista"
        : "servizio clienti"

    sendEventCustomerHelp(contactType, requestType)
  }

  const findGa4ItemFromLineItems = (order: Order) => {
    return order?.line_items
      ?.map((item) =>
        item?.metadata?.ga4Item
          ? formatProduct({
              ...item?.metadata?.ga4Item,
              quantity: item.quantity
            })
          : null
      )
      ?.filter((i) => !!i)
  }

  const sendEventCouponUse = (discount: string) => {
    const { event, sendEvent } = useGAEvent(
      `track_coupon_use_${discount ?? ""}`,
      "custom_event"
    )
    event.value = {
      event: "custom_event",
      action: "coupon_use",
      coupon_code: discount,
      coupon_value: "",
      coupon_outocome: "ko"
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventBeginCheckout = (order: Order) => {
    const { event, sendEvent } = useGAEvent(
      `track_begin_checkout_${order?.id}`,
      "custom_event"
    )
    event.value = {
      event: "begin_checkout",
      ecommerce: {
        ...cleanObject({
          currency: order?.currency_code ?? "EUR",
          value: order?.total_amount_with_taxes_float?.toString()
            ? formatPrice(order?.total_amount_with_taxes_float?.toString())
            : null
        }),
        items: findGa4ItemFromLineItems(order)
      },
      index_step: "1"
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventAddShippingInfo = (order: Order) => {
    const { event, sendEvent } = useGAEvent(
      `track_add_shipping_info_${order?.id}`,
      "custom_event"
    )
    event.value = {
      event: "add_shipping_info",
      action: "add_shipping_info",
      ecommerce: {
        ...cleanObject({
          currency: order?.currency_code ?? "",
          value: order?.total_amount_with_taxes_float ?? "",
          shipping_tier:
            order?.shipping_amount_float > 0
              ? "standard a pagamento"
              : "standard gratuita",
          coupon: order?.coupon_code ?? ""
        }),
        items: findGa4ItemFromLineItems(order)
      },
      index_step: "2"
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventAddPaymentInfo = (order: Order, payment_method?: string) => {
    const { event, sendEvent } = useGAEvent(
      `track_add_payment_info_${order?.id}`,
      "custom_event"
    )

    const payment = ref(
      payment_method ? payment_method : order.payment_method?.reference
    )
    if (
      !payment_method &&
      order.payment_method?.reference == "credit_card" &&
      order.payment_source_details?.payment_method_details?.paymentMethod &&
      order.payment_source_details.payment_method_details.paymentMethod
        .toLowerCase()
        .includes("googlepay")
    ) {
      payment.value = "google_pay"
    } else if (
      !payment_method &&
      order.payment_method?.reference == "credit_card" &&
      order.payment_source_details?.payment_method_details?.paymentMethod &&
      order.payment_source_details.payment_method_details.paymentMethod
        .toLowerCase()
        .includes("apple")
    ) {
      payment.value = "apple_pay"
    }

    event.value = {
      event: "add_payment_info",
      action: "add_payment_info",
      ecommerce: {
        ...cleanObject({
          currency: order?.currency_code ?? "",
          payment_type: payment.value,
          value: order?.total_amount_with_taxes_float ?? "",
          coupon: order?.coupon_code ?? ""
        }),
        items: findGa4ItemFromLineItems(order)
      },
      index_step: "3"
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventPurchase = (order: Order, user: DataUser | null) => {
    const { event, sendEvent } = useGAEvent(
      `track_purchase_${order?.id}`,
      "custom_event"
    )

    const transaction_id = order.reference ?? ""
    const paymentMethodDetails =
      order?.payment_source_details?.payment_method_details?.paymentMethod
    const isGooglePayPayment = paymentMethodDetails
      ?.toLowerCase()
      ?.includes("googlepay")
    const isApplePayPayment = paymentMethodDetails
      ?.toLowerCase()
      ?.includes("apple")

    event.value = {
      event: "purchase",
      action: "purchase",
      ecommerce: {
        ...cleanObject({
          transaction_id,
          value: order.total_amount_with_taxes_float ?? undefined,
          tax: order.total_tax_amount_float ?? undefined,
          shipping: order.shipping_amount_float ?? undefined,
          currency: "EUR",
          coupon: order?.coupon_code ?? "",
          coupon_value: order.discount_amount_float?.toString() ?? "0",
          shipping_tier:
            order?.shipping_amount_float && order?.shipping_amount_float > 0
              ? "standard a pagamento"
              : "standard gratuita",
          payment_type: isGooglePayPayment
            ? "googlepay"
            : isApplePayPayment
            ? "applepay"
            : order?.payment_source_details?.type ?? "postepay",
          tax_deduction: !order?.billing_address?.billing_info ? "no" : "si"
        }),
        items: findGa4ItemFromLineItems(order)
      },
      user_data: cleanObject({
        email: order.billing_address?.email ?? order.customer?.email ?? "",
        phone_number: order.billing_address?.phone ?? "",
        address: {
          first_name: order.billing_address?.first_name ?? "",
          last_name: order.billing_address?.last_name ?? "",
          street: order.billing_address?.line_1 ?? "",
          city: order.billing_address?.city ?? "",
          region: order.billing_address?.state_code ?? "",
          postal_code: order.billing_address?.zip_code ?? "",
          country: order.billing_address?.country_code ?? ""
        }
      }),
      user_data_hashed: cleanObject({
        sha256email: order.billing_address?.email
          ? sha256(order.billing_address?.email).toString()
          : order.customer?.email
          ? sha256(order.customer?.email).toString()
          : "",
        sha256phone_number: order.billing_address?.phone
          ? sha256(order.billing_address?.phone).toString()
          : "",
        address: {
          sha256first_name: order.billing_address?.first_name
            ? sha256(order.billing_address?.first_name).toString()
            : "",
          sha256last_name: order.billing_address?.last_name
            ? sha256(order.billing_address?.last_name).toString()
            : "",
          sha256street: order.billing_address?.line_1
            ? sha256(order.billing_address?.line_1).toString()
            : "",
          sha256city: order.billing_address?.city
            ? sha256(order.billing_address?.city).toString()
            : "",
          sha256region: order.billing_address?.state_code
            ? sha256(order.billing_address?.state_code).toString()
            : "",
          sha256postal_code: order.billing_address?.zip_code
            ? sha256(order.billing_address?.zip_code).toString()
            : "",
          sha256country: order.billing_address?.country_code
            ? sha256(order.billing_address?.country_code).toString()
            : ""
        }
      }),
      user_data_extra: cleanObject({
        date_of_birthday: user?.birthday ?? "",
        date_of_birthday_fb: user?.birthday
          ? user?.birthday.replace(/[\-\s]+/g, "")
          : "",
        city_fb: order.billing_address?.city
          ? order.billing_address?.city.toLowerCase().replace(/[\-\s]+/g, "")
          : ""
      })
    }
    sendEvent({ clearEcommerce: false })
  }
  /**
   * @description Send error fired from an invalid submit of form
   * @param {string} formId
   */
  const sendEventErrorForm = (formId: string) => {
    const { event, sendEvent } = useGAEvent(
      `event_error_form_${formId}`,
      "custom_event"
    )
    event.value = {
      event: "error_feedback",
      category: "error",
      action: "validation",
      form_id: formId,
      feedback_title: `${formId} invalid submit`
    }

    sendEvent({ clearEcommerce: false })
  }

  const sendEventLogin = (method: string) => {
    const { event, sendEvent } = useGAEvent(`login`, "custom_event")
    event.value = {
      event: "login",
      method: method
    }

    sendEvent({ clearEcommerce: false })
  }

  const sendEventSignUp = (method: string) => {
    const { event, sendEvent } = useGAEvent(`sign_up`, "custom_event")
    event.value = {
      event: "sign_up",
      method: method
    }

    sendEvent({ clearEcommerce: false })
  }

  const lastSearchMethod = ref("")

  const sendEventViewSearchResults = (
    method: string,
    key: string,
    results: number,
    action?: string
  ) => {
    if (lastSearchMethod.value === method + ":" + key) {
      return
    }
    lastSearchMethod.value = method + ":" + key

    const { event, sendEvent } = useGAEvent(
      `view_search_results`,
      "custom_event"
    )
    event.value = cleanObject({
      event: "view_search_results",
      method: method,
      action: action ? action : getSearchAction(),
      search_term: key,
      search_result_number: results
    })

    sendEvent({ clearEcommerce: false })
  }

  const mapProduct = (
    item: VerticalProps,
    i: number,
    listName: string = ""
  ): GA4Entities["gaItem"] => {
    const statefulEntityList:
      | [Exclude<keyof GA4Entities, "cartList" & "gaItem">]
      | undefined = inject("statefulEntityList")
    const promotion = item?.promotions?.find((p) => p.text) ?? null
    const discount = promotion
      ? Number(item?.oldPrice - item?.currentPrice).toFixed(2)
      : ""

    const newIndex = item?.position ?? i + 1 ?? 0

    return formatProduct({
      item_id: item?.productCode ?? item?.skuCode ?? "",
      item_name: item?.title ?? "",
      index: newIndex,
      item_list_name: listName ?? statefulEntityList?.value?.list_name ?? "",
      item_brand: item?.brand?.name ?? "",
      item_category: item?.categories?.main ?? item?.mainCategoryName ?? "",
      item_category2: item?.categories?.second ?? "",
      item_category3: item?.categories?.third ?? "",
      item_category4: item?.categories?.fourth ?? "",
      item_category5: item?.categories?.fifth ?? "",
      price: item?.currentPrice ?? "",
      discount: discount ?? promotion?.text ?? promotion?.theme ?? "",
      available: item?.inStock ? "si" : "no",
      quantity: 1,
      bundle_name: item?.bundleName ? item?.bundleName : "",
      currency: "EUR"
    })
  }

  return {
    sendEventAddToCart,
    sendEventRemoveFromCart,
    sendEventSaveLater,
    sendEventSelectContent,
    sendEventCustomerHelp,
    sendEventPhoneHelp,
    addQuantityFromCart,
    removeQuantityFromCart,
    saveLaterFromCart,
    sortFilter,
    customerHelp,
    sendEventCouponUse,
    sendEventBeginCheckout,
    sendEventAddShippingInfo,
    sendEventAddPaymentInfo,
    sendEventPurchase,
    sendEventErrorForm,
    updateCartList,
    sendEventLogin,
    sendEventSignUp,
    sendEventViewSearchResults,
    mapProduct
  }
}
