import type { GA4Entities } from "nuxt-ga4"
import { WrapperImageProps } from "~/components/Utils/WrapperImage.props"
import { appPaths } from "~/utils/paths/paths"

export const savedForLaterAlgoliaBaseIndex = "TOP_PRODUCTS"

export const anonymousSaveForLaterProductStorageKey = "anonymousSaveForLaterProduct"

export function navigateToSavedForLaterPage() {
  return navigateTo(appPaths.savedForLater)
}

export function getSkuItemMetadata(
  productImage: WrapperImageProps | undefined,
  slug: string | undefined,
  price: number,
  oldPrice?: number,
  stockQuantity?: number,
  unitOfMeasure?: string,
  expirationDate?: string,
  isDeductable?: boolean,
  ga4Item?: GA4Entities["gaItem"]
): LineItemMetadata {
  return {
    product_image: productImage,
    slug: `/p-${slug}`,
    price: price,
    oldPrice: oldPrice,
    inStock: stockQuantity ? stockQuantity > 0 : false,
    unitType: unitOfMeasure ?? "",
    expirationDate: expirationDate ?? "",
    isDeductable: isDeductable ?? false,
    ga4Item: ga4Item ?? {}
  } as LineItemMetadata
}

export function getSkuListItemCreateObject(
  skuId: string,
  metadata: LineItemMetadata
): _SkuListItemCreate {
  return {
    sku: { id: skuId, type: "skus" },
    metadata
  }
}

export function getSortingItemsForPersonalPage() {
  const indexName = savedForLaterAlgoliaBaseIndex
  const { t } = useI18n()

  return [
    {
      value: `${indexName}`,
      label: t("organism.myAccount.savedForLater.sorting.latest")
    },
    {
      value: `${indexName}_discount-desc`,
      label: t("organism.myAccount.savedForLater.sorting.discountDesc")
    },
    {
      value: `${indexName}_name-asc`,
      label: t("organism.myAccount.savedForLater.sorting.nameAsc")
    },
    {
      value: `${indexName}_name-desc`,
      label: t("organism.myAccount.savedForLater.sorting.nameDesc")
    },
    {
      value: `${indexName}_price-asc`,
      label: t("organism.myAccount.savedForLater.sorting.priceAsc")
    },
    {
      value: `${indexName}_price-desc`,
      label: t("organism.myAccount.savedForLater.sorting.priceDesc")
    }
  ]
}
