import type { CommerceLayerClient } from "@commercelayer/sdk"

// Returns all the prices for a list of SKUs using Commerce Layer API
export const getAllPrices = async (
  cl: CommerceLayerClient,
  skuCodes: Array<string>
) => {
  const pageSize = 20
  let maxPageNumber = 5
  let page = 1
  const arraySkus = []

  // Loop through all the pages to fetch all the prices for the given list of SKUs
  while (page <= maxPageNumber) {
    const data = await cl.skus.list({
      include: ["prices", "stock_items", "stock_items.reserved_stock"],
      pageSize,
      pageNumber: page,
      fields: {
        prices: [
          "currency_code",
          "amount_cents",
          "amount_float",
          "compare_at_amount_cents",
          "compare_at_amount_float"
        ],
        stock_items: ["quantity", "metadata", "reserved_stock"]
      },
      filters: { code_in: skuCodes.join(",") }
    })

    // If this is the first page, get the total number of pages
    if (page === 1) {
      maxPageNumber = data.meta.pageCount
    }

    // Remove meta data from response and add to the array of prices
    delete data.meta
    arraySkus.push(data)

    page++
  }

  // Flatten the array of prices and return the result
  return arraySkus.flat()
}
