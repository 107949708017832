<script
  setup
  lang="ts"
  generic="TEntity extends Exclude<keyof GA4Entities, 'cartList' & 'gaItem'>"
>
import { inject } from "vue"
import type { GA4Entities } from "nuxt-ga4"

const props = withDefaults(
  defineProps<{
    method?: string
    eventID?: string
  }>(),
  { method: "standard", eventID: "" }
)

const statefulEntityItem: GA4Entities["gaItem"] | undefined =
  inject("statefulEntityItem")

const id = props.eventID ?? inject("id")
const eventName = "select_item"
const eventKey = `event_${eventName}_${id}`
const event = "select_item"

const payload = computed(() => {
  return {
    event,
    action: eventName,
    method: props.method,
    ecommerce: {
      items: statefulEntityItem?.value ?? {}
    }
  }
})

const sendGA4EventSelectItem = () => {
  if (Object.keys(statefulEntityItem?.value ?? {}).length) {
    const { event, sendEvent } = useGAEvent(eventKey, "custom_event")
    event.value = payload.value
    sendEvent({ clearEcommerce: false })
  }
}
</script>

<template>
  <div class="w-full" @click.native.capture="sendGA4EventSelectItem">
    <slot />
  </div>
</template>
