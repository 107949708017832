<script lang="ts" setup>
import { ProductCardButtonsProps } from "./ProductCardButtons.props"
import {
  algoliaEventAddToCartAfterSearch,
  algoliaEventAddToCart
} from "~/utils/algolia"
import { PRODUCT_ADDED_TO_CART } from "~/config/algolia-insights"
import { algoliaSearch } from "~/config/algolia"
import type { Themes } from "~/components/Utils/Button.props"
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import { productUtils } from "~/utils/product"
import { availabilityLeadEvent } from "~/utils/analytics/availabilityLead"

const { t } = useI18n()
const { openModal } = useModal()
const isLogged = useIsLogged()
const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const { inStockSubscriptionItem, sendSubscription } = useInStockSubscription()

const emailState = useState("notification-subscription-mail", () => "")

const isDisabled = ref(false)

const props = withDefaults(defineProps<ProductCardButtonsProps>(), {
  userLogged: false,
  quantity: 1,
  showSaveForLater: true,
  brandTitle: ""
})

const addToCartButtonText = ref(
  props.addToCartButtonText ?? t("productTile.overall.addToCart")
)

const notified = ref(false)

const emit =
  defineEmits<{
    (e: "onAddToCart", value: number): void
    (e: "onNotifyMe"): void
  }>()

const addToCart = async (qty: number) => {
  addToCartButtonText.value = t("productTile.overall.added")
  isDisabled.value = true

  setTimeout(() => {
    addToCartButtonText.value =
      props.addToCartButtonText ?? t("productTile.overall.addToCart")
    isDisabled.value = false
  }, 2000)

  const algolia = await algoliaSearch()
  if (props.queryID) {
    algoliaEventAddToCartAfterSearch(
      algolia.indices.products,
      `${PRODUCT_ADDED_TO_CART}`,
      props.queryID,
      [`product_${props.productCode}`]
    )
  } else {
    algoliaEventAddToCart(
      algolia.indices.products,
      `${PRODUCT_ADDED_TO_CART}`,
      [`product_${props.productCode}`]
    )
  }
  emit("onAddToCart", qty)
}

const notifyMe = async () => {
  emit("onNotifyMe")
  inStockSubscriptionItem.value = {
    event: availabilityLeadEvent.availabilityLead,
    category: props.product?.metadata.ga4Item.item_list_name,
    action:
      props.product?.metadata.ga4Item.item_brand ?? props.brandTitle ?? "",
    label: props.product?.metadata.ga4Item.item_name ?? ""
  }

  if (isLogged.value) {
    emailState.value = user.value?.email ?? ""

    await sendSubscription()

    if (!notified.value) notified.value = true
    setTimeout(() => (notified.value = false), 1000)
  } else {
    openModal("notify-product-avaibility")
  }
}

const goToSimilarProducts = async () => {
  navigateTo(props.path + "#alternativeProducts")
}
</script>

<template>
  <div v-if="inStock" class="utils-button-wrapper">
    <UtilsQuantityPicker
      @onUpdateQuantity="addToCart($event)"
      v-if="showQuantity"
      :quantity="quantity"
    />
    <div class="flex" v-else>
      <UtilsButton
        @click="addToCart(1)"
        :class="`${props?.addClass ? props?.addClass : 'grow truncate'}`"
        stop
        prevent
        :text="addToCartButtonText"
        :theme="theme as Themes ?? 'contained-yellow'"
        :is-loading="isDisabled"
        :isInnerFullHeight="true"
      />
      <ProductSavedForLaterAction
        v-if="product && showSaveForLater"
        :product="product"
        :isCard="true"
        class="flex-none"
      ></ProductSavedForLaterAction>
    </div>
  </div>
  <div
    v-else-if="productUtils.isSellableStatus(sellabilityStatus)"
    class="utils-button-wrapper flex"
  >
    <UtilsButton
      stop
      prevent
      @click="notifyMe"
      class="h-11 grow truncate"
      :text="
        notified
          ? $t('productTile.overall.notified')
          : $t('productTile.overall.notifyMe')
      "
      theme="outlined-green"
      :isInnerFullHeight="true"
    >
      <template #icon>
        <UtilsIcon
          name="CheckmarkCircle2.svg"
          color="green-main"
          v-if="notified"
          class="h-5 w-5"
        />
        <UtilsIcon
          name="EmailEmpty.svg"
          color="green-main"
          v-else
          class="h-5 w-5"
        />
      </template>
    </UtilsButton>
    <ProductSavedForLaterAction
      v-if="product"
      :product="product"
      :isCard="true"
      class="flex-none"
    ></ProductSavedForLaterAction>
  </div>
  <div v-else-if="path">
    <UtilsButton
      stop
      prevent
      @click="goToSimilarProducts"
      class="w-full"
      :text="$t('productTile.overall.goToSimilarProducts')"
      :theme="theme as Themes ?? 'contained-yellow'"
    >
    </UtilsButton>
  </div>
</template>
