interface QueuableCall {
  func: (...args: any[]) => any
  args: any[]
}

const queue = ref<QueuableCall[]>([])
const executing = ref(false)

const execute = async () => {
  if (!executing.value) {
    let stackableCall = queue.value.pop()
    if (stackableCall) {
      executing.value = true
      try {
        await stackableCall.func(...stackableCall.args)
      } catch (err) {
      } finally {
        executing.value = false
        execute()
      }
    }
  }
}
const push = async (func: (...args: any[]) => any, args: any) => {
  if (process.server) {
    await func(...args)
  } else {
    queue.value.push({ func, args })
    execute()
  }
}

export default () => {
  return {
    push
  }
}
