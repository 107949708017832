import { ResultsProps } from "~/components/ProductTile/Overall/Results.props"
import { SearchProps } from "~/components/ProductTile/Overall/Search.props"

export const useSimilarProducts = (overbookedProductCode: string) => {
  const { data: result } = useLazyFetch(
    `/api/algolia/getRecommendations/recommend-3-p/${overbookedProductCode}`,
    {
      server: false
    }
  )

  const similarProducts = computed(() => {
    const products: ProductAlgolia[] = _flattenDeep(
      result?.value?.results?.map((item) => item.hits) ?? []
    )

    return mapProductsAlgolia(products).map((i: ResultsProps): SearchProps => {
      const { slug, rating, reviews, brand, mainCategoryName, ...rest } = i
      return rest
    })
  })

  return {
    similarProducts
  }
}
