// TODO: improve types
import { storeToRefs } from "pinia"
import { useUserStore } from "~/stores/user"
import { useJwt } from "@vueuse/integrations/useJwt"
import aa from "search-insights"

export const useCheckSessionUser = async (saveUser = true, reload = false) => {
  if (process.server) return false

  const userStore = useUserStore()

  const {
    userId: storedUserId,
    user: storedUser,
    sessionChecked
  } = storeToRefs(userStore)

  const {
    authToken,
    refreshToken,
    commerceLayerToken,
    resetTokens,
    refreshTokens,
    refreshCommerceLayerToken,
    isTokenExpired
  } = useSessionUserManager()

  const { $cl } = useNuxtApp()

  const getUserId = (commerceLayerToken: string) => {
    const { payload } = useJwt<CLTokenPaylod>(commerceLayerToken)
    return payload?.value?.owner.id
  }

  const saveUserIdAndFetchUser = async (userId: string) => {
    userStore.setUserId(userId)
    aa("setUserToken", userId)
    return $cl.customers.retrieve(userId)
  }

  if (isTokenExpired(authToken.value) && !refreshToken.value) {
    resetTokens()
    if (reload) userStore.logout()
    sessionChecked.value = true
    return false
  }

  if (isTokenExpired(authToken.value) && refreshToken.value) {
    try {
      const { commerceLayerToken: newCommerceLayerToken } = await refreshTokens(
        refreshToken.value
      )
      const userId = getUserId(newCommerceLayerToken)
      if (saveUser && userId) {
        const user = await saveUserIdAndFetchUser(userId)
        sessionChecked.value = true
        return user
      }
      sessionChecked.value = true
      return true
    } catch {
      resetTokens()
      userStore.logout()
      sessionChecked.value = true
      return false
    }
  }

  if (
    !isTokenExpired(authToken.value) &&
    isTokenExpired(commerceLayerToken.value)
  ) {
    try {
      const newCommerceLayerToken = await refreshCommerceLayerToken(
        authToken.value
      )

      const userId = getUserId(newCommerceLayerToken)
      if (saveUser && userId) {
        const user = await saveUserIdAndFetchUser(userId)
        sessionChecked.value = true
        return user
      }
      sessionChecked.value = true
      return true
    } catch {
      resetTokens()
      userStore.logout()
      sessionChecked.value = true
      return false
    }
  }

  if (
    !isTokenExpired(authToken.value) &&
    !isTokenExpired(commerceLayerToken.value)
  ) {
    const userId = getUserId(commerceLayerToken.value)
    if (saveUser && userId && (!storedUserId.value || !storedUser.value)) {
      const user = await saveUserIdAndFetchUser(userId)
      sessionChecked.value = true
      return user
    }
    sessionChecked.value = true
    return true
  }

  resetTokens()
  userStore.logout()
  sessionChecked.value = true
  return false
}

export type CLTokenPaylod = {
  owner: Owner
  market: Market
  rand: number
  application: Application
  test: boolean
  organization: Organization
  exp: number
}

export type Application = {
  public: boolean
  kind: string
  id: string
}

export type Market = {
  allows_external_prices: boolean
  id: string[]
  geocoder_id: null
  price_list_id: string
  stock_location_ids: string[]
}

export type Organization = {
  id: string
  slug: string
  enterprise: boolean
}

export type Owner = {
  id: string
  type: string
}
