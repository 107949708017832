import { DataUser } from "~/stores/user"
import { analyticsCustomer } from "~/utils/analytics/customer"
import sha256 from "crypto-js/sha256.js"

export enum initUserEvent {
  initUser = "init_user"
}

export enum initUserTrafficType {
  internal = "internal"
}

export interface GtmInitUserPayload {
  logged_in: boolean
  customer_type: string
  traffic_type: string
  user_id: string
  user_data: null | {}
  user_data_hashed: null | {}
  user_data_extra: null | {}
}

export interface GtmUserAddress {
  first_name: string
  last_name: string
  street: string
  city: string
  region: string
  postal_code: string
  country: string
}

export const getInitUserBasicPayload: (
  isLogged: boolean,
  customerType: string
) => GtmInitUserPayload = (isLogged: boolean, customerType: string) => {
  return {
    logged_in: isLogged,
    customer_type: customerType,
    traffic_type: "",
    user_id: "",
    user_data: null,
    user_data_hashed: null,
    user_data_extra: null
  }
}

export const getUserAddress: (
  allUserAddresses: UserAddress[],
  user: DataUser
) => GtmUserAddress = (allUserAddresses: UserAddress[], user: DataUser) => {
  const address = allUserAddresses.filter((addr) => addr.isDefaultAddress)[0]

  return {
    first_name: user?.name,
    last_name: user?.surname,
    street: address?.line1,
    city: address?.city,
    region: address?.province,
    postal_code: address?.zipCode,
    country: analyticsCustomer.country
  }
}

export const getUserData = (user: DataUser, address: GtmUserAddress | null) => {
  return {
    email: user.email,
    phone_number: user.phone
      ? analyticsCustomer.phonePrefix + user?.phone
      : null, // con country code
    ...(address && { address })
  }
}

export const getUserDataHashed = (
  user: DataUser,
  address: GtmUserAddress | null
) => {
  let userDataHashed = {}

  if (user.phone) {
    userDataHashed = {
      ...userDataHashed,
      sha256_phone_number: sha256(
        analyticsCustomer.phonePrefix + user.phone
      ).toString()
    }
  }

  if (address) {
    userDataHashed = {
      ...userDataHashed,
      address: {
        sha256_first_name: sha256(user.name).toString(),
        sha256_last_name: sha256(user.surname).toString(),
        sha256_street: address?.street
          ? sha256(address.street).toString()
          : null,
        sha256_city: address?.city ? sha256(address.city).toString() : null,
        sha256_region: address?.region
          ? sha256(address.region).toString()
          : null,
        sha256_postal_code: address?.postal_code
          ? sha256(address.postal_code).toString()
          : null,
        sha256_country: sha256(analyticsCustomer.country).toString()
      }
    }
  }

  return userDataHashed
}

export const getUserDataExtra = (
  birthday: string | null,
  address: GtmUserAddress | null
) => {
  let userDataExtra = {}

  if (birthday) {
    userDataExtra = {
      date_of_birthday: birthday,
      date_of_birthday_fb: birthday.replace(/[\-\s]+/g, "")
    }
  }

  if (address?.city) {
    userDataExtra = {
      ...userDataExtra,
      city_fb: address.city.toLowerCase().replace(/[\-\s]+/g, "")
    }
  }

  return userDataExtra
}
