export const formatAmount = (cents: number) =>
  `${(Math.abs(cents) / 100).toLocaleString('it-IT', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })} €`

export const formatPrice = (cents: number, freePriceLabel = 'Gratis') =>
  cents || !freePriceLabel ? formatAmount(cents) : freePriceLabel

export const getPercentage = (a: number, b: number) =>
  `${((Math.abs(a) / Math.abs(b)) * 100).toFixed(0)}%`
