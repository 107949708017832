<script setup lang="ts">
import type { ModalProps } from "./Modal.props"
const props = withDefaults(defineProps<ModalProps>(), {
  fullscreen: false,
  enableOnClickOutside: true,
  isWelcomeModal: false
})

const emit =
  defineEmits<{
    (e: "onClose"): void
  }>()

const modalClasses = ref({
  modalAlphaLayer: "fixed z-[99999] inset-0 bg-black-main/[65%]",
  modalBox: props.fullscreen
    ? "absolute md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 md:min-w-[min(50vh,100%)]"
    : "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[min(50vh,100%)]",
  modalBlock: "z-90",
  modalHeader: "",
  modalBody: "",
  modalClose: props.closeBtn
    ? "h-7 w-7 flex items-center justify-center absolute top-[-3px] right-[-3px] rounded-full bg-white shadow-modal-close"
    : "hidden"
})

const modalTransitionClasses = ref({
  enter: "duration-300 ease-in",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "duration-300 ease-out",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0"
})

const { initModal, closeModal } = useModal()
const bodyModal = ref()
initModal(props.id)

// @ts-ignore
if (props.enableOnClickOutside) {
  onClickOutside(bodyModal, () => {
    if (!props.isWelcomeModal) emitAndClose()
    else if (process.client && window?.Cookiebot?.hasResponse) emitAndClose()
  })
}

const emitAndClose = () => {
  emit("onClose")
  closeModal()
}
</script>

<template>
  <CustomModal
    class="modal"
    :id="id"
    :custom-classes="modalClasses"
    :custom-transitions="modalTransitionClasses"
  >
    <template #closer>
      <UtilsIcon
        name="Close.svg"
        @click="emitAndClose()"
        class="h-5 w-5"
        v-if="closeBtn"
      />
    </template>
    <template #body>
      <div id="body" ref="bodyModal" class="body-modal">
        <slot />
      </div>
    </template>
  </CustomModal>
</template>
