import { getAsPipedString, pushDataLayer } from "~/utils/analytics/analytics"
import { getClickCtaPayload } from "~/utils/analytics/clickCta"

export interface OverbookingDataLayerPayload {
  event: overbookingEvents
  category: string
  option: string
}

export interface SelectViewCartDataLayerPayload
  extends OverbookingDataLayerPayload {
  label?: string
  action?: string
}

export enum overbookingEvents {
  viewCartUpdate = "view_cart_update",
  selectCardUpdate = "select_cart_update"
}

export enum selectViewUpdateActions {
  close = "close",
  noChange = "no_change",
  update = "update"
}

export function getViewCartUpdatePayload(
  products: ProductAlgolia[],
  productTitle: string
): OverbookingDataLayerPayload {
  const pipedIdsForGtm = getAsPipedString(
    products.map((product) => product.productCode)
  )

  return {
    event: overbookingEvents.viewCartUpdate,
    category: productTitle,
    option: pipedIdsForGtm
  }
}

export function getSelectCartUpdatePayload(
  productTitle: string,
  products?: ProductAlgolia[],
  action?: selectViewUpdateActions,
  replacementId?: string
): SelectViewCartDataLayerPayload {
  const pipedIdsForGtm = products
    ? getAsPipedString(products.map((product) => product.productCode))
    : ""

  return {
    event: overbookingEvents.selectCardUpdate,
    category: productTitle,
    option: pipedIdsForGtm,
    ...(action && { action }),
    ...(replacementId && { label: replacementId })
  }
}

export function pushViewCartUpdateToDataLayer(
  products: ProductAlgolia[],
  productTitle: string
) {
  if (!products.length) {
    return
  }

  const viewCartUpdatePayload = getViewCartUpdatePayload(products, productTitle)

  pushDataLayer(viewCartUpdatePayload)
}

export function pushSelectCartUpdateToDataLayer(
  productTitle: string,
  products?: ProductAlgolia[],
  action?: selectViewUpdateActions,
  replacementId?: string
) {
  const viewCartUpdatePayload = getSelectCartUpdatePayload(
    productTitle,
    products,
    action,
    replacementId
  )

  pushDataLayer(viewCartUpdatePayload)
}

export function pushClickCtaNoChangeToDataLayer(
  context: string,
  label: string
) {
  const clickCtaNoChangePayload = getClickCtaPayload(context, label)

  pushDataLayer(clickCtaNoChangePayload)
}
