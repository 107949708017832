export const loginSignupPath = "/accedi-registrati"
export const personalAreaPath = "/area-personale"

export const appPaths = {
  home: "/",
  loginSignup: `${loginSignupPath}`,
  login: `${loginSignupPath}/login`,
  signup: `${loginSignupPath}/crea-account`,
  passwordForget: `/password-dimenticata`,
  socialLogin: "/social-login",
  savedForLater: `${personalAreaPath}/preferiti`
}
