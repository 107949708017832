import { cleanObject } from "~/utils/analytics/analytics"
import { analyticsCategories } from "~/utils/analytics/categories"
import { RouteLocationNormalized } from "vue-router"

export default defineNuxtRouteMiddleware((to, from) => {
  if (!process.client) {
    return
  }

  const isLogged = useIsLogged()
  const origin = window.location.origin
  const trafficType =
    useRuntimeConfig().public.IS_PRODUCTION == "no" ? "internal" : false

  let payload = {
    logged_in: !!isLogged?.value,
    page_type: getPageType(to),
    page_location: to.fullPath.length > 1 ? origin + to.fullPath : origin,
    page_referrer:
      to.fullPath == from.fullPath
        ? document.referrer
        : from.fullPath.length > 1
        ? origin + from.fullPath
        : origin
  }

  if (trafficType) {
    payload.traffic_type = "internal"
  }

  window.dataLayer.push(
    cleanObject({
      event: "init",
      ...payload
    })
  )
})

const getPageType = (route: RouteLocationNormalized) => {
  let pageType = analyticsCategories.other

  if (route.path === "/") {
    pageType = analyticsCategories.home
  } else if (route.name?.startsWith("b-")) {
    pageType = analyticsCategories.brand
  } else if (route.name?.startsWith("c-")) {
    pageType = analyticsCategories.category
  } else if (route.name?.startsWith("p-")) {
    pageType = analyticsCategories.product
  } else if (route.name?.startsWith("promozioni")) {
    pageType = analyticsCategories.promo
  } else if (route.name?.startsWith("area-personale")) {
    pageType = analyticsCategories.account
  } else if (route.name?.startsWith("checkout")) {
    pageType = analyticsCategories.checkout
  } else if (route.name?.startsWith("contatti")) {
    pageType = analyticsCategories.contact
  } else if (route.name?.startsWith("ricerca")) {
    pageType = analyticsCategories.search
  } else if (route.name?.startsWith("carrello")) {
    pageType = analyticsCategories.cart
  } else if (route.name?.startsWith("checkout")) {
    pageType = analyticsCategories.searchsearch
  } else if (route.name?.startsWith("top-magazine")) {
    pageType = analyticsCategories.magazine
  } else if (route.name?.startsWith("enciclopedia")) {
    pageType = analyticsCategories.encyclopedia
  }
  return pageType
}
