import { defineNuxtPlugin } from "nuxt/app"
import { storeToRefs } from "pinia"
import { useUserStore } from "~/stores/user"

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { $i18n } = nuxtApp

  router.beforeResolve((to, from) => {
    const { event, sendEvent } = useGAEvent(
      `track_page_event_ga4_${to.name}`,
      "page_view"
    )

    const userStore = useUserStore()
    const { userId, user } = storeToRefs(userStore)
    const birthDate = new Date(
      Date.parse(user.value?.birthday?.split("/")?.reverse()?.join("-") ?? "")
    )
    const difference = Date.now() - birthDate.getTime()
    const ageDate = new Date(difference)
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970)

    const UA = navigator.userAgent
    const responsive =
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        UA
      )
        ? "Mobile"
        : /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(UA)
        ? "Tablet"
        : "Desktop"

    event.value = {
      event: "page_view",
      page_type: to?.meta?.pageType ?? to?.name ?? "generic",
      customer_id: userId.value ?? "",
      customer_email: user.value?.email ?? "",
      customer_age: user.value ? calculatedAge?.toString() ?? "" : "",
      customer_type: user.value?.status ?? "",
      login_status: user.value ? "logged" : "guest",
      store_country: "it",
      site_language: $i18n?.locale?.value,
      currency: "EUR",
      responsive_layout: responsive ?? "Desktop"
    }
    sendEvent({ clearEcommerce: false })
  })
})
