import { useBreakpoints } from "@vueuse/core"

export const screensObj = {
  sm: 360,
  md: 1024,
  lg: 1601
}

const screens = {
  sm: screensObj.sm + "px",
  md: screensObj.md + "px",
  lg: screensObj.lg + "px"
}

const breakpoints = useBreakpoints(screens)

export const sm = breakpoints.smaller("md")
export const md = breakpoints.greaterOrEqual("md")
export const lg = breakpoints.greaterOrEqual("lg")
