import { analyticsCategories } from "~/utils/analytics/categories"
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import { customerTypes, getCustomerType } from "~/utils/analytics/customer"

export const useAnalytics = () => {
  const route = useRoute()
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)

  const pageType: ComputedRef<string> = computed(() => {
    let pageType = analyticsCategories.other
    const routeName = route.name as string

    if (route.path === "/") {
      pageType = analyticsCategories.home
    } else if (routeName?.startsWith("b-")) {
      pageType = analyticsCategories.brand
    } else if (routeName?.startsWith("c-")) {
      pageType = analyticsCategories.category
    } else if (routeName?.startsWith("p-")) {
      pageType = analyticsCategories.product
    } else if (routeName?.startsWith("promozioni")) {
      pageType = analyticsCategories.promo
    } else if (routeName?.startsWith("area-personale")) {
      pageType = analyticsCategories.account
    } else if (routeName?.startsWith("checkout")) {
      pageType = analyticsCategories.checkout
    } else if (routeName?.startsWith("contatti")) {
      pageType = analyticsCategories.contact
    } else if (routeName?.startsWith("ricerca")) {
      pageType = analyticsCategories.search
    } else if (routeName?.startsWith("carrello")) {
      pageType = analyticsCategories.cart
    } else if (routeName?.startsWith("checkout")) {
      pageType = analyticsCategories.search
    } else if (routeName?.startsWith("top-magazine")) {
      pageType = analyticsCategories.magazine
    } else if (routeName?.startsWith("enciclopedia")) {
      pageType = analyticsCategories.encyclopedia
    }

    return pageType
  })

  const customerType: ComputedRef<
    customerTypes.acquired | customerTypes.prospect | customerTypes.repeat
  > = computed(() => {
    if (!user?.value) {
      return customerTypes.prospect
    }

    const customerStatus = user.value.status

    return getCustomerType(customerStatus)
  })

  return {
    pageType,
    customerType
  }
}
