<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import { CheckboxProps } from "./Checkbox.props"
import { ValidationVisiblityEnum } from "~/types/validation"

const props = withDefaults(defineProps<CheckboxProps>(), {
  label: "",
  modelValue: false,
  isDisabled: false,
  isRequired: false,
  isBig: false,
  validationVisibility: ValidationVisiblityEnum.Blur
})

const emit =
  defineEmits<{
    (e: "update:modelValue", value: boolean): void
    (e: "clicked"): void
  }>()

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    if (!value) value = false
    emit("update:modelValue", value)
  }
})

const checkbox = ref()

const isValid = computed(() => {
  if (checkbox.value) {
    const node = checkbox.value.node
    if (node.context.state.dirty && node.context.state.rules)
      return node.context.state.complete
  }
  return true
})

watch(isValid, (newValue) => {
  emit("onUpdateValidity", newValue)
})

const validation = computed(() => (props.isRequired ? "required" : ""))

let isHover = ref(false)
</script>

<template>
  <div class="checkbox-wrapper">
    
<FormKitLazyProvider config-file="true">
<FormKit
      :id="id"
      ref="checkbox"
      :name="name"
      v-model="value"
      type="checkbox"
      :label="label"
      :validation="(props?.validation as string) || validation"
      :validation-visibility="props?.validationVisibility"
      :classes="{
        wrapper: {
          'flex items-center gap-x-2': true,
          'group cursor-pointer': !isDisabled,
          'opacity-40': isDisabled
        },
        inner: 'rounded-[3px]',
        label:
          'beaver label text-grey-800 select-none transition-colors group-hover:text-grey-600',
        input: 'absolute w-0 h-0 opacity-0 select-none event-none hidden'
      }"
      :disabled="isDisabled"
      @click="emit('clicked')"
    >
      <template #decorator>
        <div
          @mouseover="isHover = true"
          @mouseleave="isHover = false"
          tabindex="0"
          class="
            'hover:border-green-main
            place-items-center
            rounded-[3px]
            border-2
            focus:outline-blue-100
          "
          :class="[
            isBig ? 'h-6 w-6' : 'h-3.5 w-3.5',
            {
              'border-black-40': value === false,
              'border-green-main': value !== false,
              'border-green-300': value !== false && isHover,
              'focus:outline focus:outline-1 focus:outline-offset-2 focus:outline-green-10 focus:ring-2 focus:ring-green-10':
                !isDisabled,
              'focus:border-black-80': value !== true && !isDisabled,
              'border-grey-200 focus:outline-none': isDisabled
            }
          ]"
        >
          <Transition name="fade" mode="out-in">
            <IconsCheckmark
              v-if="value"
              class="bg-green-main text-white"
              :class="[
                isBig ? 'h-[20px] w-[20px]' : 'h-[10px] w-[10px]',
                { 'bg-green-300': isHover }
              ]"
            />
          </Transition>
        </div>
      </template>

      <template #label>
        <UtilsMarkdown
          class="
            beaver
            label
            text-grey-800
            group-hover:text-grey-600
            select-none
            transition-colors
          "
          v-if="label"
          :content="label"
          :inline="true"
          tag="span"
        />
      </template>
    </FormKit>
</FormKitLazyProvider>

  </div>
</template>
